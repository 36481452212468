import React, { FC, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../common/api";
import { useFormik } from "formik";
import * as Yup from "yup";

interface Values {
  credit: number;
}

export const CreditValue: FC = () => {
  const [entities, setEntities] = useState<LegalDetails>();

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/legalDetails?type=credit`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const updateCreditValue = async (values: Values) => {
    try {
      const { data }: any = await api({
        url: `/legalDetails`,
        method: "post",
        body: {
          details: {
            value: values.credit,
          },

          type: "credit",
        },
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      credit: (entities?.details?.value as number) || 0,
    },
    validationSchema: Yup.object({
      credit: Yup.number()
        .required("Credit is required")
        .min(0, "less than 0 not allowed"),
    }),
    onSubmit: (values: Values) => {
      updateCreditValue(values);
    },
  });

  return (
    <div>
      <Card>
        <CardHeader title="Credit Value"></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4  col-form-label"
                >
                  1 HKD =
                </label>
                <div className="col-sm-4 col-lg-6 input-group">
                  <input
                    type="number"
                    className="form-control"
                    id="inputText"
                    name="credit"
                    min={0}
                    value={formik.values.credit}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text">CREDIT</span>
                  </div>
                  {formik.touched.credit && formik.errors.credit ? (
                    <div className="text-danger mt-1 ml-1 mb-4 pb-4">
                      {formik.errors.credit}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className={`btn btn-success mr-2 mx-sm-2`}
                onClick={() => formik.handleSubmit()}
              >
                Update
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
