import React, { FC, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../common/api";

interface Values {
  gradeId: string;
  content: string;
}

export const PushNotification: FC = () => {
  const [entities, setEntities] = useState<PushNotification[]>([]);

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/grade/activeGrade`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdateNotification = async (values: Values) => {
    try {
      const body = {
        content: values.content,
        gradeId: values.gradeId,
        isAll: values.gradeId == "all" ? true : false,
      };
      const { data }: any = await api({
        url: "/notification/toAgent",
        method: "post",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      gradeId: "",
      content: "",
    },
    validationSchema: Yup.object({
      gradeId: Yup.string().required("Agent Level is required"),
      content: Yup.string().required("Content is required"),
    }),
    onSubmit: (values: Values) => {
      UpdateNotification(values);
    },
  });

  return (
    <Card>
      <CardHeader title="Push Notification"></CardHeader>
      <CardBody>
        <div className="row">
          <div className="col-lg-12">
            <div className="mt-4 mb-3">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-2 py-0 col-form-label"
                >
                  Content
                </label>
                <div className="col-sm-8">
                  <textarea
                    className="form-control"
                    id="inputText"
                    rows={3}
                    value={formik.values.content || ""}
                    name="content"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.touched.content && formik.errors.content ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.content}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row align-items-center ">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-2 py-0 col-form-label"
                >
                  Agent Level
                </label>
                <div className="col-sm-4">
                  <div className="input-group relative d-flex align-items-center">
                    <select
                      className="form-control top-select rounded"
                      name="gradeId"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.gradeId}
                      style={{ paddingRight: "3rem" }}
                    >
                      <option value="" disabled>
                        -- Select --
                      </option>
                      <option value="all">All</option>
                      {entities?.length > 0 &&
                        entities.map((option: PushNotification) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                    <ExpandMoreRoundedIcon
                      style={{
                        position: "absolute",
                        right: "1rem",
                        zIndex: 10,
                      }}
                    />
                  </div>
                  {formik.touched.gradeId && formik.errors.gradeId ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.gradeId}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="mt-4 mt-sm-0">
            <button
              className="btn btn-success mr-2 mx-sm-2"
              onClick={() => formik.handleSubmit()}
            >
              Send
            </button>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};
