import React, { FC, useState, useEffect } from "react";
import { useFormik } from "formik";
import {
  createStyles,
  makeStyles,
  Theme,
  useTheme,
} from "@material-ui/core/styles";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import Chip from "@material-ui/core/Chip";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../common/api";

interface Values {
  appAdmin: string[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
      maxWidth: 300,
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
    noLabel: {
      marginTop: theme.spacing(3),
    },
  })
);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export const AppAdmin: FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  const [entities, setEntities] = useState<ActiveAgent[]>([]);
  const [appAdmin, setAppAdmin] = useState<AppAdmin[]>([]);

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/user/activeAgent`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data.userData);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getAppAdmin = async () => {
    try {
      const { data }: any = await api({
        url: `/user/getAppAdmin`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setAppAdmin(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getData();
    getAppAdmin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdateAppAdmin = async (values: Values) => {
    try {
      const body = {
        ids: values.appAdmin,
      };
      const { data }: any = await api({
        url: `/user/updateAppAdmin`,
        method: "post",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      appAdmin: appAdmin?.map((admin: AppAdmin) => admin._id) || [],
    },
    validationSchema: Yup.object({
      appAdmin: Yup.array()
        .min(1, "Atleast one app admin is required.")
        .required("Atleast one app admin is required.")
        .nullable(),
    }),
    onSubmit: (values: Values) => {
      UpdateAppAdmin(values);
    },
  });

  const options = entities.map((option) => {
    return {
      label: `${option.name} - ${option.phone}`,
      value: option._id,
    };
  });

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    formik.setFieldValue("appAdmin", event.target.value as string[]);
  };

  return (
    <Card>
      <CardHeader title="App Admin"></CardHeader>
      <CardBody>
        <div className="row">
          <div className="col-lg-6">
            <div className="mt-4 mb-3">
              <div className="form-group row align-items-center ">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
                >
                  App Admin
                </label>
                <div className="col-sm-8">
                  <div className="input-group relative d-flex align-items-center mt-3 mt-sm-0 w-100">
                    <Select
                      labelId="demo-mutiple-chip-label"
                      id="demo-mutiple-chip"
                      multiple
                      value={formik.values.appAdmin}
                      onChange={handleChange}
                      input={<Input id="select-multiple-chip" />}
                      renderValue={(selected) => (
                        <div className={classes.chips}>
                          {(selected as string[]).map((value) => (
                            <Chip
                              key={value}
                              label={
                                options.find((option) => option.value === value)
                                  ?.label || ""
                              }
                              className={classes.chip}
                            />
                          ))}
                        </div>
                      )}
                      MenuProps={MenuProps}
                    >
                      {options.map((option) => (
                        <MenuItem
                          key={option.value}
                          value={option.value}
                          style={getStyles(
                            option.value,
                            formik.values.appAdmin,
                            theme
                          )}
                        >
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  {formik.touched.appAdmin && formik.errors.appAdmin ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.appAdmin}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="mt-4 mt-sm-0">
            <button
              className="btn btn-success mr-2 mx-sm-2"
              onClick={() => formik.handleSubmit()}
            >
              Update
            </button>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};
