import React, { FC, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../common/api";

interface Values {
  agentId: string;
}

export const SalesTarget: FC = () => {
  const [entities, setEntities] = useState<MonthlyAgentCredit[]>([]);

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/user/activeAgent`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data.userData);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const AddAgent = async (values: Values) => {
    try {
      const { data }: any = await api({
        url: `/transmission/transaction/${values.agentId}`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      agentId: "",
    },
    validationSchema: Yup.object({
      agentId: Yup.string().required("Agent name is required"),
    }),
    onSubmit: (values: Values) => {
      AddAgent(values);
    },
  });

  return (
    <Card>
      <CardHeader title="Sales Target"></CardHeader>
      <CardBody>
        <div className="row">
          <div className="col-lg-6">
            <div className="mt-4 mb-3">
              <div className="form-group row align-items-center ">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
                >
                  Agent Name
                </label>
                <div className="col-sm-8">
                  <div className="input-group relative d-flex align-items-center">
                    <select
                      className="form-control top-select rounded"
                      name="agentId"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.agentId}
                      style={{ paddingRight: "3rem" }}
                    >
                      <option value="" disabled>
                        -- Select --
                      </option>
                      {entities?.length > 0 &&
                        entities.map((option: MonthlyAgentCredit) => (
                          <option key={option._id} value={option._id}>
                            {option.name}
                          </option>
                        ))}
                    </select>
                    <ExpandMoreRoundedIcon
                      style={{
                        position: "absolute",
                        right: "1rem",
                        zIndex: 10,
                      }}
                    />
                  </div>
                  {formik.touched.agentId && formik.errors.agentId ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.agentId}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="mt-4 mt-sm-0">
            <button
              className="btn btn-success mr-2 mx-sm-2"
              onClick={() => formik.handleSubmit()}
            >
              Add
            </button>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};
