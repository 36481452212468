import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { IconButton } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../helpers";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { api } from "../../../common/api";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Select } from "../../../common/components/Select";

interface Params {
  id?: string;
}

interface Values {
  name: string;
  description: string;
  photo: string;
  customerReward: number;
  agencyReward: number;
  itemCode: string;
  status: boolean;
}

export const AddEditProduct: FC = () => {
  const history = useHistory();
  const params: Params = useParams();

  const inputFile = useRef<HTMLInputElement | null>(null);
  const [entities, setEntities] = useState<ProductAdd>();
  const [imgLoaded, setImgLoaded] = useState<boolean>(false);

  const getGiftData = async () => {
    try {
      const { data }: any = await api({
        url: `/product/${params.id}`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      //@ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    params.id && getGiftData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadImage = async (e: ChangeEvent) => {
    e.preventDefault();
    if (inputFile.current?.files) {
      const formData = new FormData();
      formData.append("file", inputFile.current.files[0]);
      try {
        const { data }: any = await api({
          url: "/file/file-upload",
          method: "post",
          body: formData,
        });
        if (data.serverResponse.isError) {
          !params.id && formik.setFieldError("photo", "image is required");
          toast.error(data.serverResponse.message);
        } else {
          formik.setFieldValue("photo", data.data.fileUrl);
          toast.success("image uploaded");
        }
      } catch (err) {
        formik.setFieldError("photo", "image is required");
        toast.error("image not uploaded, please try again");
      }
    }
  };

  const addProduct = async (values: Values) => {
    try {
      const body = {
        name: values.name,
        description: values.description,
        photo: values.photo,
        customerReward: values.customerReward,
        agencyReward: values.agencyReward,
      };
      const { data }: any = await api({
        url: "/product",
        method: "post",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Product added");
        history.push("/product");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };
  const updateProduct = async (values: Values) => {
    try {
      const body = {
        name: values.name,
        description: values.description,
        photo: values.photo,
        customerReward: values.customerReward,
        agencyReward: values.agencyReward,
        isActive: values.status,
      };

      const { data }: any = await api({
        url: `/product/${params.id}`,
        method: "put",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Product updated");
        history.push("/product");
      }
    } catch (err) {
      //@ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: params.id ? (entities?.name as string) : "",
      description: params.id ? (entities?.description as string) : "",
      photo: params.id ? (entities?.photo as string) : "",
      customerReward: params.id ? (entities?.customerReward as number) : 0,
      agencyReward: params.id ? (entities?.agencyReward as number) : 0,
      itemCode: params.id ? (entities?.itemCode as string) : "",
      status: params.id ? (entities?.isActive as boolean) : true,
    },

    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      description: Yup.string().required("Description is required"),
      photo: Yup.string().required("Image is required"),
      customerReward: Yup.number()
        .required("Customer credit is required ")
        .min(0, "Customer credit is required"),
      agencyReward: Yup.number()
        .required("Agency credit is required")
        .min(0, "Agency credit is required"),
    }),
    onSubmit: (values: Values) => {
      params.id ? updateProduct(values) : addProduct(values);
    },
  });

  useEffect(() => {
    if (formik.values.photo === "") {
      setImgLoaded(false);
    }
  }, [formik.values.photo]);

  return (
    <div>
      <Card>
        <CardHeader
          title={params.id ? "Product Details" : "Add Product"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 col-form-label"
                >
                  Product Name
                </label>
                <div className="col-sm-6 ">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="form-group row">
                <label htmlFor="inputText" className="col-form-label">
                  Customer Credit
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control"
                    id="inputText"
                    name="customerReward"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.customerReward}
                  />
                  {formik.touched.customerReward &&
                  formik.errors.customerReward ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.customerReward}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-3 ">
              <div className="form-group row">
                <label htmlFor="inputText" className=" col-form-label">
                  Agency Credit
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control"
                    id="inputText"
                    name="agencyReward"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.agencyReward}
                  />
                  {formik.touched.agencyReward && formik.errors.agencyReward ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.agencyReward}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {Boolean(params.id) && (
            <div className="row">
              <div className="col-lg-6 ">
                <div className="form-group row">
                  <label
                    htmlFor="inputText"
                    className="col-sm-2 col-md-2 col-lg-4 col-form-label"
                  >
                    Item Code
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="inputText"
                      name="itemCode"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.itemCode}
                      readOnly={Boolean(params.id)}
                      disabled={Boolean(params.id)}
                    />
                    {formik.touched.itemCode && formik.errors.itemCode ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.itemCode}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className="form-group row">
            <label
              htmlFor="inputText"
              className="col-sm-4 col-md-2 col-lg-2  col-form-label"
            >
              Product Description
            </label>
            <div className="col-sm-8 ">
              <textarea
                className="form-control"
                id="inputText"
                rows={3}
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.description}
              />
              {formik.touched.description && formik.errors.description ? (
                <div className="text-danger mt-1 ml-1">
                  {formik.errors.description}
                </div>
              ) : null}
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputText"
              className="col-sm-4 col-md-2 col-lg-2 col-form-label"
            >
              Product Photo
            </label>
            <div className="col-sm-6">
              {formik.values.photo && (
                <div className="w-50 h-100">
                  <input
                    className="w-100 h-100"
                    type="file"
                    onChange={uploadImage}
                    ref={inputFile}
                    hidden
                    name="upload-image"
                    id="upload-image"
                    accept="image/jpg, image/png, image/jpeg"
                  />
                  <div className="position-relative w-200px">
                    <img
                      src={formik?.values?.photo || ""}
                      alt=""
                      className="img-fluid"
                      onClick={() => {
                        let input = document.getElementById(
                          "upload-image"
                        )! as HTMLInputElement;
                        input.value = "";
                        inputFile.current?.click();
                      }}
                      onLoad={() => setImgLoaded(true)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/media/bg/image-not-found.jpeg";
                      }}
                    />
                    {imgLoaded && (
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        className="position-absolute delete-image-btn"
                        onClick={() => formik.setFieldValue("photo", "")}
                      >
                        <CloseRoundedIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              )}
              {!formik.values.photo && (
                <div className="p-1 upload-image mt-4">
                  <input
                    className="w-50 h-100"
                    type="file"
                    onChange={uploadImage}
                    ref={inputFile}
                    hidden
                    name="upload-image"
                    id="upload-image"
                    accept="image/jpg, image/png, image/jpeg"
                  />
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    onClick={() => {
                      let input = document.getElementById(
                        "upload-image"
                      )! as HTMLInputElement;
                      input.value = "";
                      inputFile.current?.click();
                    }}
                    className="image-add-icon"
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Files/File-plus.svg"
                      )}
                    />
                  </IconButton>
                </div>
              )}
              {formik.touched.photo && formik.errors.photo ? (
                <div className="text-danger mb-0 mt-4 pt-1">
                  {formik.errors.photo}
                </div>
              ) : null}
            </div>
          </div>
          {params?.id && (
            <div className="row mt-1">
              <div className="col-12 col-sm-2 mb-sm-0 ws-nowrap">Status</div>
              <div className="col-10 col-sm-4">
                <Select
                  value={formik.values.status}
                  setValue={(value: string) =>
                    formik.setFieldValue("status", value)
                  }
                  options={[
                    { label: "Active", value: true },
                    { label: "Inactive", value: false },
                  ]}
                />
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                {params.id ? "Save" : "Add"}
              </button>
            </div>
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/product">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
