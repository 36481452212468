import React, { FC, useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../common/api";

interface Params {
  id?: string;
}
interface Values {
  type: string;
  account: string;
  bank: string;
  account_name: string;
  account_number: string;
}
interface Select {
  value: string;
  label: string;
}

export const CompanyAccount: FC = () => {
  const [entities, setEntities] = useState<LegalDetails>();
  const selectOptions = [
    { value: "PhoneNumber", label: "Phone Number" },
    { value: "FPSID", label: "FPS ID" },
    { value: "Email", label: "Email " },
  ];

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/legalDetails?type=company_account`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };
  useEffect(() => {
    getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateData = async (values: Values) => {
    try {
      const body = {
        details: {
          fps: {
            type: values.type,
            account: values.account,
          },
          bank: {
            bank: values.bank,
            account_name: values.account_name,
            account_number: values.account_number,
          },
        },
        type: "company_account",
      };
      const { data }: any = await api({
        url: `/legalDetails`,
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: (entities?.details?.fps?.type as string) || "Phone Number",
      account: (entities?.details?.fps?.account as string) || "",
      bank: (entities?.details?.bank?.bank as string) || "",
      account_name: (entities?.details?.bank?.account_name as string) || "",
      account_number: (entities?.details?.bank?.account_number as string) || "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Type is required"),
      account: Yup.string().required("Account is required"),
      bank: Yup.string().required("Bank Name is required"),
      account_name: Yup.string().required("Bank Account is required"),
      account_number: Yup.string().required("Bank Account Number is required"),
    }),
    onSubmit: (values: Values) => {
      updateData(values);
    },
  });

  return (
    <Card>
      <CardHeader title="Company Account"></CardHeader>
      <CardBody>
        <div className="mb-5">
          <strong>FPS</strong>
        </div>

        <div className="row">
          <div className="col-lg-6 ">
            <div className="form-group row">
              <label
                htmlFor="inputText"
                className="col-sm-4  col-md-2 col-lg-4 col-form-label"
              >
                Type
              </label>
              <div className="col-sm-6">
                <div className="input-group relative d-flex align-items-center">
                  <select
                    className="form-control top-select rounded"
                    name="type"
                    onChange={(e) => {
                      formik.handleChange(e);
                      if (e.target.value === "Product") {
                        formik.setFieldValue("box", "");
                      }
                    }}
                    value={formik.values.type}
                    style={{ paddingRight: "3rem" }}
                  >
                    {selectOptions.map((option: Select, ind: number) => (
                      <option key={ind} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <ExpandMoreRoundedIcon
                    style={{
                      position: "absolute",
                      right: "1rem",
                      zIndex: 10,
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 ">
            <div className="form-group row">
              <label
                htmlFor="inputText"
                className="col-sm-4  col-md-2 col-lg-4 col-form-label"
              >
                Account
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="inputText"
                  name="account"
                  min={0}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.account}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="mb-5">
          <strong>BANK</strong>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group row align-items-center ">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                Bank
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="bank"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.bank}
                  />
                </div>
                {formik.touched.bank && formik.errors.bank ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.bank}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="form-group row align-items-center ">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                Bank Account
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="account_name"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.account_name}
                  />
                </div>
                {formik.touched.account_name && formik.errors.account_name ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.account_name}
                  </div>
                ) : null}
              </div>
            </div>{" "}
          </div>
        </div>

        <div className="row">
          <div className="col-lg-6">
            <div className="form-group row align-items-center ">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                Bank Account Number
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="account_number"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.account_number}
                  />
                </div>
                {formik.touched.account_number &&
                formik.errors.account_number ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.account_number}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="mt-4 mt-sm-0">
            <button
              className="btn btn-success mr-2 mx-sm-2"
              // @ts-ignore
              onClick={formik.handleSubmit}
            >
              Update
            </button>
          </div>
          {/* <div className="mt-4 mt-sm-0">
            <Link className="btn btn-primary" to="/transmission">
              Back
            </Link>
          </div> */}
        </div>
      </CardFooter>
    </Card>
  );
};
