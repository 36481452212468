import React, { FC, useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../partials/controls";
import { toast } from "react-toastify";
import moment from "moment";
import { api } from "../../../../common/api";
import { Select } from "../../../../common/components/Select";
import { useFormik } from "formik";
import * as Yup from "yup";

interface Params {
  id: string;
}

interface Values {
  myCredit: number;
  status: boolean;
  name: string;
  cashoutType: string;
  shippingAddress: string;
  shippingRegion: string;
  shippingReceiver: string;
}

export const CustomerDetails: FC = () => {
  const history = useHistory();
  const params: Params = useParams();

  const [entities, setEntities] = useState<UserEntities>();

  const updateCustomerDetails = async (values: Values) => {
    try {
      const body = {
        isActive: values.status,
        name: values.name,
        myCredit: values.myCredit,
        cashoutType: values.cashoutType,
        shippingAddress: {
          region: values?.shippingRegion,
          address: values.shippingAddress,
        },
        shippingReceiver: values.shippingReceiver,
      };

      const { data }: any = await api({
        url: `/user/updateUser/${params.id}`,
        method: "put",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Customer details updated");
        history.push("/member/customer");
      }
    } catch (err) {
      //@ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getCustomerData = async () => {
    try {
      const { data }: any = await api({
        url: `/user/userDetails/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        data.result.data.createdAt = moment(data.result.data.createdAt).format(
          "YYYY/MM/DD"
        );
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    params.id && getCustomerData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: params.id ? (entities?.name as string) : "",
      myCredit: params.id ? (entities?.myCredit as number) : 0,
      shippingReceiver: params.id ? (entities?.shippingReceiver as string) : "",

      shippingAddress: params.id
        ? (entities?.shippingAddress?.address as string)
        : "",
      shippingRegion: params.id
        ? (entities?.shippingAddress?.region as string)
        : "",
      status: params.id ? (entities?.isActive as boolean) : true,
      cashoutType: params.id
        ? (entities?.cashoutType as string) || "fps"
        : "fps",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      // shippingAddress: Yup.string().required("Shipping Address is required"),
      // shippingReceiver: Yup.string().required("Contact Person Name is required"),
    }),
    onSubmit: (values: Values) => {
      updateCustomerDetails(values);
    },
  });

  return (
    <div>
      <Card>
        <CardHeader title="Customer Detail"></CardHeader>
        <CardBody>
          <div className="row mt-4">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Customer Name
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="name"
                    value={formik.values.name || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Phone Number
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="inputText"
                    readOnly
                    value={entities?.phone || ""}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Credit
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control"
                    name="myCredit"
                    id="inputText"
                    readOnly
                    value={entities?.myCredit || 0}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2 col-lg-4 col-form-label"
                >
                  Member Id
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?._id || ""}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Registration Date
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?.createdAt || ""}
                  />
                </div>
              </div>

              {/* <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2 col-lg-4 col-form-label"
                >
                  Cashout Payment
                </label>
                <div className="col-sm-6">
                  <Select
                    value={formik.values.cashoutType}
                    setValue={(value: string) =>
                      formik.setFieldValue("cashoutType", value)
                    }
                    options={[
                      { label: "FPS", value: "FPS" },
                      { label: "Bank Account", value: "Bank Account" },
                      { label: "Cash", value: "Cash" },
                    ]}
                  />
                </div>
              </div> */}
            </div>
          </div>

          {/* <div>
            <div className="form-group row">
              <label
                htmlFor="inputText"
                className="col-sm-4 col-md-2 col-lg-2  col-form-label"
              >
                Shipping Address
              </label>
              <div className="col-sm-6 ">
                <textarea
                  className="form-control"
                  id="inputText"
                  rows={3}
                  value={formik.values.shippingAddress || ""}
                  name="shippingAddress"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                ></textarea>
                {formik.touched.shippingAddress &&
                formik.errors.shippingAddress ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.shippingAddress}
                  </div>
                ) : null}
              </div>
            </div>
          </div> */}
          <div className="row mt-4">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Shipping Region
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="shippingRegion"
                    value={formik.values.shippingRegion || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.shippingRegion &&
                  formik.errors.shippingRegion ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.shippingRegion}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Shipping Address
                </label>
                <div className="col-sm-6">
                  <textarea
                    className="form-control"
                    id="inputText"
                    rows={3}
                    value={formik.values.shippingAddress || ""}
                    name="shippingAddress"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.touched.shippingAddress &&
                  formik.errors.shippingAddress ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.shippingAddress}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Contact Person
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="shippingReceiver"
                    value={formik.values.shippingReceiver || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.shippingReceiver &&
                  formik.errors.shippingReceiver ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.shippingReceiver}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          {params?.id && (
            <div className="row mt-1">
              <div className="col-12 col-sm-2 mb-sm-0 ws-nowrap">Status</div>
              <div className="col-10 col-sm-3">
                <Select
                  value={formik.values.status}
                  setValue={(value: string) =>
                    formik.setFieldValue("status", value)
                  }
                  options={[
                    { label: "Active", value: true },
                    { label: "Inactive", value: false },
                  ]}
                />
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                {params.id ? "Save" : "Add"}
              </button>
            </div>
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/member/customer">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
