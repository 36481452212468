import React, { FC, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { api } from "../../common/api";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKModules } from "../../common/editor/ckModules";
interface Values {
  description: string;
}

export const AgentTermsAndConditions: FC = () => {
  const [entities, setEntities] = useState<LegalDetails>();
  const [data, setData] = useState("");
  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/legalDetails?type=agent_terms_and_condition`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const handleChange = (e, editor) => {
    const data = editor.getData();
    setData(data);
    formik.setFieldValue("description", data);
  };
  const updateData = async (values: Values) => {
    try {
      const body = {
        details: {
          description: values.description.replaceAll("<p><br></p>", "<p></p>"),
        },

        type: "agent_terms_and_condition",
      };
      const { data }: any = await api({
        url: `/legalDetails`,
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      description: entities?.details?.description
        ? (entities?.details?.description as string)
        : "",
    },
    validationSchema: Yup.object({
      description: Yup.string().required("Description is required"),
    }),
    onSubmit: (values: Values) => {
      updateData(values);
    },
  });

  return (
    <div>
      <Card>
        <CardHeader title="Agent's Terms and Conditions"></CardHeader>
        <CardBody>
          <div className="row mt-4">
            <div className="col-10">
              <div className="">
                <CKEditor
                  editor={ClassicEditor}
                  config={CKModules}
                  className="ck"
                  data={formik.values.description || ""}
                  onChange={handleChange}
                  onBlur={() => formik.setFieldTouched("description", true)}
                />

                {formik.touched.description && formik.errors.description ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.description}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Update
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
