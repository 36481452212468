import React, { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { sortCaret, headerSortingClasses, toAbsoluteUrl } from "../../helpers";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../partials/controls";

import { SizePerPage } from "../../common/table/filters/SizePerPage";
import { Table } from "../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import { sortNumber } from "../../common/table/sorter";
import { FilterQuery } from "../../common/table/filters/FilterQuery";
import { api } from "../../common/api";
import { Link } from "react-router-dom";
import { Dialog } from "../../common/modal";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import { IconButton } from "@material-ui/core";

export const Blacklist: FC = () => {
  const [query, setQuery] = useState<string>("");
  const [entities, setEntities] = useState<BlackListEntities[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [id, setId] = useState<string | null>(null);

  const renderActionButton = (ind: string) => {
    return (
      <IconButton
        disableRipple
        disableFocusRipple
        className="btn action-btn delete-action-btn"
        onClick={() => setId(ind)}
        disabled={Boolean(id)}
      >
        <DeleteForeverRoundedIcon />
      </IconButton>
    );
  };

  const deleteEntity = async () => {
    try {
      const { data }: any = await api({
        url: `/blacklist/${id}`,
        method: "delete",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setTotalData((prev) => {
          return prev - 1;
        });
        setEntities((prev) => {
          return prev.filter((entity) => entity._id !== data.result.data._id);
        });
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getEntities = async (page = 1, sizePerPage = 10) => {
    const isQuery = query !== "" ? `&search=${query}` : "";
    try {
      const { data }: any = await api({
        url: `/blacklist?page=${page}&size=${sizePerPage}${isQuery}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.blackListData.map(
          (entity: BlackListEntities) => {
            return {
              ...entity,
              action: renderActionButton(entity._id),
            };
          }
        );
        setTotalData(data.result.data.countBlackList);
        setEntities(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      dataField: "phone",
      text: "Phone Number",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortNumber(field, order, setEntities),
    },
    {
      dataField: "action",
      text: "Delete",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      {totalData} records ({totalData === 0 ? 0 : (page - 1) * sizePerPage + 1}{" "}
      to {to})
    </span>
  );

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        getEntities(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getEntities(props.page, props.sizePerPage);
      }
    }
  };

  return (
    <Card>
      <CardHeader title="Blacklist">
        <CardHeaderToolbar>
          <Link className="btn btn-primary" to="/blacklist/add">
            Add Blacklist
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex flex-column flex-sm-row flex-wrap">
                <SizePerPage
                  paginationProps={paginationProps}
                  handleSizePerPage={handleSizePerPage}
                  entities={entities}
                />
                <FilterQuery query={query} setQuery={setQuery} title="Search" />
                <button
                  className="btn btn-primary mt-4"
                  onClick={() => {
                    setPage(1);
                    getEntities(1, sizePerPage);
                  }}
                >
                  <span className="navigation-icon icon-white ">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </button>
              </div>
              <Table
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                keyField="phone"
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
            </>
          )}
        </PaginationProvider>
      </CardBody>
      {Boolean(id) && (
        <Dialog
          name="delete"
          show={id}
          onHide={() => setId(null)}
          action={deleteEntity}
          headerText="Are you sure?"
          bodyText="Are you sure you want to remove this member from blacklist?"
          loadingText="Removing member from blacklist..."
          actionText="Remove"
          actionStyle="danger"
        />
      )}
    </Card>
  );
};
