import React, { Suspense } from "react";
import { Redirect, Switch } from "react-router-dom";
import { AboutUs } from "../components/about-us";
import { AddEditArticle } from "../components/article/add-edit-article";
import { AgentRegistrationApproval } from "../components/approval/agent-registration";
import { AgentUpgradeApproval } from "../components/approval/agent-upgrade";
import { Cashout } from "../components/approval/cashout";
import { GiftRedemption } from "../components/approval/gift-redemption";
import { GiftRedemptionApproval } from "../components/approval/gift-redemption/gift-approval";
import { Blacklist } from "../components/blacklist";
import { AddToBlackList } from "../components/blacklist/add-blacklist";
import { Eshop } from "../components/eshop";
import { AddEditGift } from "../components/eshop/add-edit-gift";
import { GradeInfo } from "../components/grade-info";
import { GradeLevel } from "../components/grade-level";
import { AddEditGradeLevel } from "../components/grade-level/add-edit-grade-level";
import { PrivacyPolicy } from "../components/privacy-policy";
import { TermsAndConditions } from "../components/terms-and-conditions";
import { AgentTermsAndConditions } from "../components/agent-term-condition";
import { QrCode } from "../components/qr-code";
import { LayoutSplashScreen, ContentRoute } from "../layout";
import { Product } from "../components/product";
import { AddEditProduct } from "../components/product/add-edit-product";
import { AgentRegistrationProfile } from "../components/approval/agent-registration/agent-profile";
import { AgentCashoutUpdate } from "../components/approval/cashout/cashout-update";
import { AgentUpgradeProfile } from "../components/approval/agent-upgrade/agent-profile";
import { Article } from "../components/article";
import { CreditValue } from "../components/credit-value";
import { SpecialConfig } from "../components/special-config";
import { ContactUs } from "../components/contact-us";
import { OfficialSiteUrl } from "../components/official-site-url";
import { Transmission } from "../components/transmission";
import { TransmissionDetail } from "../components/transmission/transmission-detail";
import { CompanyAccount } from "../components/company-account";
import { CashBank } from "../components/cash-bank";
import { CashBankUpdate } from "../components/cash-bank/cash-update";
import { MonthlyAgentCredit } from "../components/approval/monthly-agent-credit";
import { MonthlyAgentCreditApprove } from "../components/approval/monthly-agent-credit/credit-approval";
import { MonthlyAgentCreditAdd } from "../components/approval/monthly-agent-credit/add-agent";
import { Agent } from "../components/member/agent";
import { AgentDetails } from "../components/member/agent/agent-details";
import { Customer } from "../components/member/customer";
import { CustomerDetails } from "../components/member/customer/customer-details";
import { SalesTarget } from "../components/sales-target";
import { AdminList } from "../components/member/admin";
import { AddAdmin } from "../components/member/admin/add-admin";
import { AppAdmin } from "../components/app-admin";
import { PushNotification } from "../components/push-notification";
import { ItemMaintenance } from "../components/item-maintenance";
import { ItemMaintenanceDetail } from "../components/item-maintenance/item-details";
import { AddAgent } from "../components/member/agent/add-agent";

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/article" />
          // <Redirect exact from="/" to="/auth/login" />
        }
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/dashboard" to="/article" />
        }

        {/* article */}
        <ContentRoute exact path="/article" component={Article} />
        <ContentRoute exact path="/article/add" component={AddEditArticle} />
        <ContentRoute
          exact
          path="/article/update/:id"
          component={AddEditArticle}
        />

        {/* approval */}
        <ContentRoute
          exact
          path="/approval/agent-registration"
          component={AgentRegistrationApproval}
        />
        <ContentRoute
          exact
          path="/approval/agent-registration/update/:id"
          component={AgentRegistrationProfile}
        />
        <ContentRoute
          exact
          path="/approval/agent-upgrade"
          component={AgentUpgradeApproval}
        />
        <ContentRoute
          exact
          path="/approval/agent-upgrade/update/:id"
          component={AgentUpgradeProfile}
        />
        <ContentRoute exact path="/approval/cashout" component={Cashout} />
        <ContentRoute
          exact
          path="/approval/cashout/update/:id"
          component={AgentCashoutUpdate}
        />
        <ContentRoute
          exact
          path="/approval/gift-redemption"
          component={GiftRedemption}
        />
        <ContentRoute
          exact
          path="/approval/gift-redemption/update/:id"
          component={GiftRedemptionApproval}
        />
        <ContentRoute
          exact
          path="/approval/monthly-agent-credit"
          component={MonthlyAgentCredit}
        />
        <ContentRoute
          exact
          path="/approval/monthly-agent-credit/approve/:id"
          component={MonthlyAgentCreditApprove}
        />
        <ContentRoute
          exact
          path="/approval/monthly-agent-credit/add"
          component={MonthlyAgentCreditAdd}
        />

        {/* member */}
        <ContentRoute exact path="/member/admin" component={AdminList} />
        <ContentRoute exact path="/member/admin/add" component={AddAdmin} />
        <ContentRoute exact path="/member/agent" component={Agent} />
        <ContentRoute exact path="/member/agent/add" component={AddAgent} />
        <ContentRoute
          exact
          path="/member/agent/update/:id"
          component={AgentDetails}
        />
        <ContentRoute exact path="/member/customer" component={Customer} />
        <ContentRoute
          exact
          path="/member/customer/update/:id"
          component={CustomerDetails}
        />

        {/* grade level */}
        <ContentRoute exact path="/grade-level" component={GradeLevel} />
        <ContentRoute
          exact
          path="/grade-level/add"
          component={AddEditGradeLevel}
        />
        <ContentRoute
          exact
          path="/grade-level/update/:id"
          component={AddEditGradeLevel}
        />

        {/* blacklist */}
        <ContentRoute exact path="/blacklist" component={Blacklist} />
        <ContentRoute exact path="/blacklist/add" component={AddToBlackList} />

        {/* product */}
        <ContentRoute exact path="/product" component={Product} />
        <ContentRoute exact path="/product/add" component={AddEditProduct} />
        <ContentRoute
          exact
          path="/product/update/:id"
          component={AddEditProduct}
        />

        {/* eshop */}
        <ContentRoute exact path="/eshop" component={Eshop} />
        <ContentRoute exact path="/eshop/add-gift" component={AddEditGift} />
        <ContentRoute
          exact
          path="/eshop/update-gift/:id"
          component={AddEditGift}
        />

        {/* transmission */}
        <ContentRoute exact path="/transmission" component={Transmission} />
        <ContentRoute
          exact
          path="/transmission/transmision-detail/:id"
          component={TransmissionDetail}
        />

        {/* sales target */}
        <ContentRoute exact path="/sales-target" component={SalesTarget} />

        {/* push notification */}
        <ContentRoute
          exact
          path="/push-notification"
          component={PushNotification}
        />

        {/* content management */}
        <ContentRoute exact path="/about-us" component={AboutUs} />
        <ContentRoute exact path="/contact-us" component={ContactUs} />

        {/* settings */}
        <ContentRoute exact path="/app-admin" component={AppAdmin} />
        <ContentRoute exact path="/privacy-policy" component={PrivacyPolicy} />
        <ContentRoute
          exact
          path="/terms-and-conditions"
          component={TermsAndConditions}
        />
        <ContentRoute
          exact
          path="/agent-terms-and-conditions"
          component={AgentTermsAndConditions}
        />
        <ContentRoute
          exact
          path="/company-account"
          component={CompanyAccount}
        />
        <ContentRoute
          exact
          path="/official-site-url"
          component={OfficialSiteUrl}
        />
        <ContentRoute exact path="/credit-value" component={CreditValue} />
        <ContentRoute exact path="/special-config" component={SpecialConfig} />

        {/* cash bank */}
        <ContentRoute exact path="/cash-bank" component={CashBank} />
        <ContentRoute exact path="/cash-bank/add" component={CashBankUpdate} />
        <ContentRoute
          exact
          path="/cash-bank/update/:_id"
          component={CashBankUpdate}
        />

        {/* grade info */}
        <ContentRoute exact path="/grade-info" component={GradeInfo} />

        {/* item maintenance */}
        <ContentRoute
          exact
          path="/item-maintenance"
          component={ItemMaintenance}
        />
        <ContentRoute
          exact
          path="/item-maintenance/:id"
          component={ItemMaintenanceDetail}
        />

        {/* QR Code */}
        <ContentRoute exact path="/qr-code" component={QrCode} />
      </Switch>
    </Suspense>
  );
}
