import React, { FC } from "react";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../../../common/api";

interface Values {
  name: string;
  email: string;
  phone: string;
  password: string;
}

export const AddAdmin: FC = () => {
  const history = useHistory();

  const addAdmin = async (values: Values) => {
    let body = {
      name: values.name,
      email: values.email,
      phone: values.phone.toString(),
      password: values.password,
    };
    try {
      const { data }: any = await api({
        url: `/admin/register`,
        method: "post",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
        history.push("/member/admin");
      }
    } catch (err) {
      //@ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      email: "",
      phone: "",
      password: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      email: Yup.string()
        .email("email is invalid")
        .required("Email is required"),
      phone: Yup.number().required("Phone number is required"),
      password: Yup.string().required("Password is required"),
    }),
    onSubmit: (values: Values) => {
      addAdmin(values);
    },
  });

  return (
    <div>
      <Card>
        <CardHeader title="Add Admin"></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group row align-items-center">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
                >
                  Name
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    id="admin-name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row align-items-center">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 py-0 mb-lg-0"
                >
                  Email
                </label>
                <div className="col-sm-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      id="admin-email"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row align-items-center">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 py-0 mb-lg-0"
                >
                  Phone
                </label>
                <div className="col-sm-6">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      name="phone"
                      id="admin-phone"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.phone}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row align-items-center">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 py-0 mb-lg-0"
                >
                  Password
                </label>
                <div className="col-sm-6">
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      name="password"
                      id="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.password && formik.errors.password ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.password}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div
            className="d-flex flex-wrap align-items-center justify-content-between"
            style={{ marginTop: "-1.5rem" }}
          >
            <div className="">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Add
              </button>
            </div>
            <div className="">
              <Link className="btn btn-primary" to="/member/admin">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
