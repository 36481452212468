import React, { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { sortCaret, headerSortingClasses, toAbsoluteUrl } from "../../helpers";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../partials/controls";

import { Link } from "react-router-dom";
import { SizePerPage } from "../../common/table/filters/SizePerPage";
import { Table } from "../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import { sortDateString, sortString } from "../../common/table/sorter";
import { FilterQuery } from "../../common/table/filters/FilterQuery";
import { api } from "../../common/api";

export const Product: FC = () => {
  const history = useHistory();
  const [entities, setEntities] = useState<ProductEntities[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [query, setQuery] = useState<string>("");

  const getEntities = async (page = 1, sizePerPage = 10) => {
    const isQuery = query !== "" ? `&search=${query}` : "";
    try {
      const { data }: any = await api({
        url: `/product?page=${page}&size=${sizePerPage}${isQuery}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.productData.map(
          (entity: ProductEntities, ind: number) => {
            return {
              ...entity,
              // id: ++ind + (page - 1) * sizePerPage,
              status: entity.isActive ? "Active" : "Inactive",
              createdAt: moment(entity.createdAt).format("YYYY-MM-DD HH:mm"),
            };
          }
        );
        setTotalData(data.result.data.countProduct);
        setEntities(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      dataField: "itemCode",
      text: "Item Code",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setEntities),
    },
    {
      dataField: "name",
      text: "Product Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setEntities),
    },
    {
      dataField: "createdAt",
      text: "Create Date",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortDateString(field, order, setEntities),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setEntities),
    },
  ];

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,

    onSelect: (data: ProductEntities) =>
      history.push(`/product/update/${data._id}`),
  };

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      {totalData} records ({totalData === 0 ? 0 : (page - 1) * sizePerPage + 1}{" "}
      to {to})
    </span>
  );

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        getEntities(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getEntities(props.page, props.sizePerPage);
      }
    }
  };

  return (
    <Card>
      <CardHeader title="Product List">
        <CardHeaderToolbar>
          <Link className="btn btn-primary" to="/product/add">
            Add Product
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex flex-column flex-sm-row flex-wrap">
                <SizePerPage
                  paginationProps={paginationProps}
                  handleSizePerPage={handleSizePerPage}
                  entities={entities}
                />
                <FilterQuery query={query} setQuery={setQuery} title="Search" />

                <button
                  className="btn btn-primary mt-4 ml-2"
                  onClick={() => {
                    setPage(1);
                    getEntities(1, sizePerPage);
                  }}
                >
                  <span className="navigation-icon icon-white">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </button>
              </div>

              <Table
                getSelectRow={getSelectRow}
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                keyField="_id"
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
            </>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};
