import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { IconButton } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../../helpers";
import SVG from "react-inlinesvg";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../partials/controls";
import { toast } from "react-toastify";
import moment from "moment";
import { api } from "../../../../common/api";
import { Select } from "../../../../common/components/Select";
import { useFormik } from "formik";
import * as Yup from "yup";

interface Params {
  id: string;
}

interface Values {
  status: boolean;
  name: string;
  cashoutType: string;
  shippingRegion: string;
  shippingAddress: string;
  agentLevel: any;
  agentToken: string;
  shippingReceiver: string;
  photo: string;
  registrationDate: string;
  hkId: string;
  affilatedBy: any;
  myCredit: number;
}

export const AgentDetails: FC = () => {
  const history = useHistory();
  const params: Params = useParams();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [imgLoaded, setImgLoaded] = useState<boolean>(false);
  const [entities, setEntities] = useState<UserEntities>();
  const [agentLevelOptions, setAgentLevelOptions] = useState<AgentLevel[]>();
  const [affilatedBy, setAffilatedBy] = useState<any>();

  const getAgentData = async () => {
    try {
      const { data }: any = await api({
        url: `/user/userDetails/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        data.result.data.createdAt = moment(data.result.data.createdAt).format(
          "YYYY/MM/DD"
        );
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getAffilatedBy = async () => {
    try {
      const { data }: any = await api({
        url: `/user/activeAgent`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const removeCurrentAgent = data.result.data.userData.filter((item) => item._id !== params.id);
        setAffilatedBy(removeCurrentAgent);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getAgentLevelData = async () => {
    try {
      const { data }: any = await api({
        url: `/grade/activeGrade`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setAgentLevelOptions(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const updateAgentDetail = async (values: Values) => {
    try {
      let body: any = {
        agentLevel: values?.agentLevel,
        isActive: values.status,
        name: values.name,
        hkId: values.hkId,
        hkIdImage: values.photo,
        cashoutType: values.cashoutType,
        registrationDate: values.registrationDate,
        cashoutDetails: {
          bankName: entities?.cashoutDetails?.bankName,
          accountNumber: entities?.cashoutDetails?.accountNumber,
        },
        myCredit: values?.myCredit,
        shippingAddress: {
          region: values?.shippingRegion,
          address: values.shippingAddress,
        },
        shippingReceiver: values.shippingReceiver,
        affilatedBy: values.affilatedBy,
      };
      if (entities?.agentToken !== values.agentToken) {
        body = {
          ...body,
          agentToken: values.agentToken,
        };
      }
      const { data }: any = await api({
        url: `/user/updateUser/${params.id}`,
        method: "put",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Agent details updated");
        history.push("/member/agent");
      }
    } catch (err) {
      //@ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const uploadImage = async (e: ChangeEvent) => {
    e.preventDefault();
    if (inputFile.current?.files) {
      const formData = new FormData();
      formData.append("file", inputFile.current.files[0]);
      try {
        const { data }: any = await api({
          url: "/file/file-upload",
          method: "post",
          body: formData,
        });
        if (data.serverResponse.isError) {
          !params.id && formik.setFieldError("photo", "image is required");
          toast.error(data.serverResponse.message);
        } else {
          formik.setFieldValue("photo", data.data.fileUrl);
          toast.success("image uploaded");
        }
      } catch (err) {
        formik.setFieldError("photo", "image is required");
        toast.error("image not uploaded, please try again");
      }
    }
  };

  useEffect(() => {
    params.id && getAgentData();
    getAgentLevelData();
    getAffilatedBy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const affilatedOptions =
    (affilatedBy?.length > 0 &&
      affilatedBy.map((option: any) => ({
        label: ` ${option.name} - ${option.phone}`,
        value: `${option._id}`,
      }))) ||
    [];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      photo: params.id ? (entities?.hkIdImage as string) : "",
      registrationDate: params.id ? (entities?.registrationDate as string) : "",
      agentLevel: params.id ? (entities?.agentLevel?._id as string) : "",
      name: params.id ? (entities?.name as string) : "",
      hkId: params.id ? (entities?.hkId as string) : "",
      myCredit: params.id ? (entities?.myCredit as number) : 0,
      shippingReceiver: params.id ? (entities?.shippingReceiver as string) : "",
      agentToken: params.id ? (entities?.agentToken as string) : "",
      shippingAddress: params.id
        ? (entities?.shippingAddress?.address as string)
        : "",
      shippingRegion: params.id
        ? (entities?.shippingAddress?.region as string)
        : "",
      status: params.id ? (entities?.isActive as boolean) : true,
      cashoutType:
        params.id && entities?.cashoutType
          ? (entities?.cashoutType as string)
          : "fps",
      affilatedBy: params.id ? (entities?.affilatedBy?._id as string) : "",

    },
    validationSchema: Yup.object({
      agentToken: Yup.string().required("Agent id is required"),
      name: Yup.string().required("Name is required"),
      myCredit: Yup.number()
        .required("Credit is required")
        .min(0, "Less than 0 not allowed"),
      cashoutType: Yup.string().required("Cashout type is required"),
      registrationDate: Yup.string().required("Registration is required"),
      affilatedBy: Yup.string().required("Agent member name is required"),
    }),
    onSubmit: (values: Values) => {
      updateAgentDetail(values);
    },
  });

  const options =
    agentLevelOptions?.map((item: any) => ({
      label: item.name,
      value: item._id,
    })) || [];

  return (
    <div>
      <Card>
        <CardHeader title="Agent Detail"></CardHeader>
        <CardBody>
          <div className="row mt-4">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Agent Name
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="name"
                    value={formik.values.name || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Agent Id
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="agentToken"
                    value={formik.values.agentToken || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.agentToken && formik.errors.agentToken ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.agentToken}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Phone Number
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    name="phone"
                    id="inputText"
                    readOnly
                    value={entities?.phone || ""}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  HKID no.
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="hkId"
                    name="hkId"
                    value={formik.values.hkId || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.hkId && formik.errors.hkId ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.hkId}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2 col-lg-4 col-form-label"
                >
                  Member Id
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?._id || ""}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Registration Date
                </label>
                <div className="col-sm-6">
                  <input
                    type="date"
                    className="form-control"
                    id="registrationDate"
                    name="registrationDate"
                    value={moment(
                      new Date(formik.values.registrationDate).toUTCString()
                    ).format("YYYY-MM-DD")}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.registrationDate &&
                  formik.errors.registrationDate ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.registrationDate}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2 col-lg-4 col-form-label"
                >
                  HKID Photo
                </label>
                <div className="col-sm-6">
                  {formik.values.photo && (
                    <div className="w-50 h-100">
                      <input
                        className="w-100 h-100"
                        type="file"
                        onChange={uploadImage}
                        ref={inputFile}
                        hidden
                        name="upload-image"
                        id="upload-image"
                        accept="image/jpg, image/png, image/jpeg"
                      />
                      <div className="position-relative w-200px">
                        <img
                          src={formik?.values?.photo || ""}
                          alt=""
                          className="img-fluid"
                          onClick={() => {
                            let input = document.getElementById(
                              "upload-image"
                            )! as HTMLInputElement;
                            input.value = "";
                            inputFile.current?.click();
                          }}
                          onLoad={() => setImgLoaded(true)}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "/media/bg/image-not-found.jpeg";
                          }}
                        />
                        {imgLoaded && (
                          <IconButton
                            disableRipple
                            disableFocusRipple
                            className="position-absolute delete-image-btn"
                            onClick={() => formik.setFieldValue("photo", "")}
                          >
                            <CloseRoundedIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  )}
                  {!formik.values.photo && (
                    <div className="p-1 upload-image mt-4 ">
                      <input
                        className="w-50 h-100"
                        type="file"
                        onChange={uploadImage}
                        ref={inputFile}
                        hidden
                        name="upload-image"
                        id="upload-image"
                        accept="image/jpg, image/png, image/jpeg"
                      />
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        onClick={() => {
                          let input = document.getElementById(
                            "upload-image"
                          )! as HTMLInputElement;
                          input.value = "";
                          inputFile.current?.click();
                        }}
                        className="image-add-icon"
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Files/File-plus.svg"
                          )}
                        />
                      </IconButton>
                    </div>
                  )}
                  {formik.touched.photo && formik.errors.photo ? (
                    <div className="text-danger mb-0 mt-4 pt-1">
                      {formik.errors.photo}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Credit
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control"
                    name="myCredit"
                    id="myCredit"
                    value={formik.values.myCredit}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    min={0}
                  />
                  {formik.touched.myCredit && formik.errors.myCredit ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.myCredit}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2 col-lg-4 col-form-label"
                >
                  Cashout Payment
                </label>

                <div className="col-sm-6">
                  <Select
                    value={formik.values.cashoutType}
                    setValue={(value: string) =>
                      formik.setFieldValue("cashoutType", value)
                    }
                    options={[
                      { label: "FPS", value: "fps" },
                      { label: "Bank Account", value: "bank account" },
                      { label: "Cash", value: "cash" },
                    ]}
                  />
                  {formik.touched.cashoutType && formik.errors.cashoutType ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.cashoutType}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Shipping Region
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="shippingRegion"
                    value={formik.values.shippingRegion || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.shippingRegion &&
                  formik.errors.shippingRegion ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.shippingRegion}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Shipping Address
                </label>
                <div className="col-sm-6">
                  <textarea
                    className="form-control"
                    id="inputText"
                    rows={3}
                    value={formik.values.shippingAddress || ""}
                    name="shippingAddress"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.touched.shippingAddress &&
                  formik.errors.shippingAddress ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.shippingAddress}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2 col-lg-4 col-form-label"
                >
                  Contact Person
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="shippingReceiver"
                    value={formik.values.shippingReceiver || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.shippingReceiver &&
                  formik.errors.shippingReceiver ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.shippingReceiver}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
            <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2 col-lg-4 col-form-label"
                >
                  Affilated By
                </label>
                <div className="col-sm-6">
                  <Select
                    value={formik.values.affilatedBy}
                    setValue={(value: string) =>
                      formik.setFieldValue("affilatedBy", value)
                    }
                    options={affilatedOptions}
                  />
                </div>
              </div>
            </div>
          </div>
          {params?.id && (
            <div className="row mt-4">
              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputText"
                    className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                  >
                    Status
                  </label>
                  <div className="col-sm-6">
                    <Select
                      value={formik.values.status}
                      setValue={(value: string) =>
                        formik.setFieldValue("status", value)
                      }
                      options={[
                        { label: "Active", value: true },
                        { label: "Inactive", value: false },
                      ]}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputText"
                    className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                  >
                    Agent Level
                  </label>
                  <div className="col-sm-6">
                    <Select
                      value={formik.values.agentLevel}
                      setValue={(value: string) =>
                        formik.setFieldValue("agentLevel", value)
                      }
                      options={options}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                {params.id ? "Save" : "Add"}
              </button>
            </div>
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/member/agent">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
