import React, { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import moment from "moment";
import {
  sortCaret,
  headerSortingClasses,
  toAbsoluteUrl,
} from "../../../helpers";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../partials/controls";

import { Link } from "react-router-dom";
import { SizePerPage } from "../../../common/table/filters/SizePerPage";
import { Table } from "../../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import { sortNumber, sortString } from "../../../common/table/sorter";
import { api } from "../../../common/api";
import { FilterQuery } from "../../../common/table/filters/FilterQuery";
import { IconButton } from "@material-ui/core";
import { Dialog } from "../../../common/modal";
import DeleteForeverRoundedIcon from "@material-ui/icons/DeleteForeverRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";

export const AdminList: FC = () => {
  const [query, setQuery] = useState<string>("");
  const [entities, setEntities] = useState<Admin[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [id, setId] = useState<string | null>(null);
  const [passwordModal, setPasswordModal] = useState<string | null>(null);

  const renderActionButton = (ind: string) => {
    return (
      <IconButton
        disableRipple
        disableFocusRipple
        className="btn action-btn delete-action-btn"
        onClick={() => setId(ind)}
        // disabled={Boolean(id)}
      >
        <DeleteForeverRoundedIcon />
      </IconButton>
    );
  };

  const renderEditButton = (password: any) => {
    return (
      <IconButton
        disableRipple
        disableFocusRipple
        className="btn action-btn edit-action-btn "
        onClick={() => setPasswordModal(password)}
        //   disabled={Boolean(passwordModal)}
      >
        <EditRoundedIcon />
      </IconButton>
    );
  };
  const getEntities = async (page = 1, sizePerPage = 10) => {
    const isQuery = query !== "" ? `&search=${query}` : "";
    try {
      const { data }: any = await api({
        url: `/user/allAdminUserList?page=${page}&size=${sizePerPage}${isQuery}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.userData.map((entity: Admin) => {
          return {
            ...entity,
            status: entity.isActive ? "Active" : "Inactive",
            createdAt: moment(entity.createdAt).format("YYYY-MM-DD HH:mm"),
            action: renderActionButton(entity._id),
            edit: renderEditButton(entity._id),
          };
        });
        setTotalData(data.result.data.countUser);
        setEntities(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      dataField: "name",
      text: "Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortString(field, order, setEntities),
    },
    {
      dataField: "email",
      text: "email",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortNumber(field, order, setEntities),
    },
    {
      dataField: "phone",
      text: "Mobile Number",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortNumber(field, order, setEntities),
    },
    {
      dataField: "edit",
      text: "Edit Password",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
    {
      dataField: "action",
      text: "Delete",
      sort: false,
      sortCaret: sortCaret,
      headerSortingClasses,
    },
  ];
  const disableUser = async () => {
    try {
      const { data }: any = await api({
        url: `/user/deleteUser/${id}`,
        method: "delete",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        getEntities();
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };
  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      {totalData} records ({totalData === 0 ? 0 : (page - 1) * sizePerPage + 1}{" "}
      to {to})
    </span>
  );

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        getEntities(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getEntities(props.page, props.sizePerPage);
      }
    }
  };

  return (
    <Card>
      <CardHeader title="Admin">
        <CardHeaderToolbar>
          <Link className="btn btn-primary" to="/member/admin/add">
            Add Admin
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex flex-column flex-sm-row">
                <SizePerPage
                  paginationProps={paginationProps}
                  handleSizePerPage={handleSizePerPage}
                  entities={entities}
                />
                <FilterQuery query={query} setQuery={setQuery} title="Search" />
                <button
                  className="btn btn-primary mt-4"
                  onClick={() => {
                    setPage(1);
                    getEntities(1, sizePerPage);
                  }}
                >
                  <span className="navigation-icon icon-white">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </button>
              </div>

              <Table
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
            </>
          )}
        </PaginationProvider>
      </CardBody>
      {Boolean(id) && (
        <Dialog
          name="delete"
          show={id}
          onHide={() => setId(null)}
          action={disableUser}
          headerText="Are you sure?"
          bodyText="Are you sure you want to remove this member from admin?"
          loadingText="Removing member from admin"
          actionText="Remove"
          actionStyle="danger"
        />
      )}
      {Boolean(passwordModal) && (
        <Dialog
          name="edit"
          show={passwordModal}
          onHide={() => setPasswordModal(null)}
          action=""
          headerText="Password"
          bodyText="Are you sure you want to edit password?"
          loadingText="Password changed..."
          actionText="Modify"
          actionStyle="primary"
        />
      )}
    </Card>
  );
};
