import React, { FC, useEffect, useState } from "react";

import { useHistory, useParams, Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardFooter,
} from "../../../../partials/controls";
import * as Yup from "yup";
import "react-quill/dist/quill.snow.css";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { api } from "../../../../common/api";
import { Select } from "../../../../common/components/Select";
interface Params {
  id?: string;
}
interface Values {
  reject: boolean;
  callApiReject: boolean;
  callApiApproved: boolean;
  rejectReason: string;
  shipping: boolean;
  shipment: string;
  shippingCode: string;
  call: string;
}
interface Body {
  _id: string;
  status: string;
  reason?: string;
  shipment?: string;
  shippingCode?: string;
}

export const GiftRedemptionApproval: FC = () => {
  const history = useHistory();
  const params: Params = useParams();

  const [entities, setEntities] = useState<GiftApproval>();

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/approval/giftApprovalDetails/${params.id}`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const resetBeforeApprove = () => {
    formik.setFieldValue("call", "ACCEPTED");
    formik.setFieldValue("reject", false);
    formik.setFieldValue("rejectReason", formik.values.rejectReason);
    formik.setFieldValue("callApiReject", false);
    formik.setFieldValue("callApiApproved", true);
    formik.setFieldValue("shipping", true);
  };

  const resetBeforeSave = () => {
    formik.setFieldValue("call", "SAVE");
    formik.setFieldValue("reject", false);
    formik.setFieldValue("rejectReason", formik.values.rejectReason);
    formik.setFieldValue("callApiReject", false);
    formik.setFieldValue("callApiApproved", true);
    formik.setFieldValue("shipping", true);
  };

  const resetBeforeReject = () => {
    formik.setFieldValue("call", "Rejected");
    formik.setFieldValue("shipping", false);
    formik.setFieldValue("shippingCode", "");
    formik.setFieldValue("callApiApproved", false);
    formik.setErrors({});
  };

  useEffect(() => {
    if (params.id) {
      getData();
    } else {
      history.push("/approval/gift-redemption");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reject: false,
      callApiReject: false,
      rejectReason: "",
      call: "",
      shipping: false,
      callApiApproved: false,
      shippingCode: params.id ? (entities?.shippingCode as string) : "",
      shipment: params.id ? (entities?.shipment as string) : "處理中",
    },
    onSubmit: (values: Values) => {
      if (values.call === "Rejected") {
        handleReject();
      }
      if (values.call === "ACCEPTED") {
        handleApproved(values.call);
      }
      if (values.call === "SAVE") {
        handleApproved(values.call);
      }
    },
    validationSchema: Yup.object({
      shippingCode: Yup.string().when("call", {
        is: "Approved",
        then: Yup.string()
          .required("ShippingCode is required")
          .test("shippingCode", "ShippingCode is required", (value) => {
            return value !== "";
          }),
      }),
      rejectReason: Yup.string().when("reject", {
        is: true,
        then: Yup.string().required("Reject Reason is required"),
      }),
      shipment: Yup.string().when("call", {
        is: "Approved",
        then: Yup.string()
          .required("Shipping is required")
          .test("shipping", "Shipping is required", (value) => {
            return value !== "";
          }),
      }),
    }),
  });

  const handleReject = async () => {
    formik.setFieldValue("reject", true);
    formik.setFieldValue("callApiReject", true);
    if (formik.values.reject) {
      if (formik.values.callApiReject) {
        await handleApproveReject(entities, "REJECTED");
        formik.setFieldValue("reject", false);
        formik.setFieldValue("callApiReject", false);
      } else {
        formik.setFieldValue("callApiReject", false);
      }
    }
  };

  const handleApproved = async (val: any) => {
    // formik.setFieldValue("shipping", true);
    // formik.setFieldValue("callApiApproved", true);
    if (formik.values.shipping) {
      if (formik.values.callApiApproved) {
        await handleApproveReject(entities, val);
        formik.setFieldValue("shipping", false);
        formik.setFieldValue("callApiApproved", false);
      } else {
        formik.setFieldValue("callApiApproved", false);
      }
    }
  };

  const handleApproveReject = async (values: any, status: string) => {
    try {
      let body: Body = {
        _id: values._id,
        status: status,
      };
      if (status === "REJECTED" && formik.values.rejectReason !== "") {
        body.reason = formik.values.rejectReason;
      }
      if (status === "ACCEPTED") {
        body.shipment = formik.values.shipment;
        body.shippingCode = formik.values.shippingCode;
      }
      if (status === "SAVE") {
        body.shipment = formik.values.shipment;
        body.shippingCode = formik.values.shippingCode;
      }
      const { data }: any = await api({
        url: "/admin/gift/approve",
        method: "post",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        status === "ACCEPTED" || status === "SAVE"
          ? toast.success("Approved")
          : toast.success("Rejected");

        history.push("/approval/gift-redemption");
      }
    } catch (err) {
      status === "ACCEPTED" || status === "SAVE"
        ? resetBeforeApprove()
        : resetBeforeReject();
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  return (
    <div>
      <Card>
        <CardHeader title="Gift Redemption Approval"></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Customer Name
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?.requestedBy?.name || ""}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Customer Number
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?.requestedBy?.phone || ""}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Shipping Region
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    name="shippingRegion"
                    value={entities?.requestedBy?.shippingAddress?.region || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Shipping Address
                </label>
                <div className="col-sm-6">
                  <textarea
                    className="form-control"
                    id="inputText"
                    rows={3}
                    readOnly
                    value={
                      entities?.requestedBy?.shippingAddress?.address || ""
                    }
                    name="shippingAddress"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Shipment
                  <br />
                  (Fill in if approve)
                </label>

                <div className=" col-sm-6">
                  <Select
                    value={formik.values.shipment}
                    setValue={(value: string) =>
                      formik.setFieldValue("shipment", value)
                    }
                    options={[
                      { label: "In processing", value: "處理中" },
                      { label: "In delivery", value: "送貨中" },
                      { label: "Arrived", value: "已送達" },
                      { label: "Cancelled", value: "已取消" },
                    ]}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Shipment Code
                  <br />
                  (Fill in if approve)
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="shippingCode"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.shippingCode}
                  />
                  {formik.touched.shippingCode && formik.errors.shippingCode ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.shippingCode}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Shipping Method
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={
                      entities?.cartId?.isCourierFee
                        ? "10 credit as shipping"
                        : "Cash on delivery"
                    }
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Contact Person
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?.requestedBy?.shippingReceiver}
                  />
                </div>
              </div>
            </div>
          </div>

          {entities?.cartId?.giftIds?.map((gift: any, ind: number) => (
            <div className="row" key={ind}>
              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputText"
                    className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                  >
                    {++ind}. Gift Photo
                  </label>
                  <div className="col-sm-6">
                    <img
                      src={gift?.giftId?.imageUrl || ""}
                      width="250px"
                      height="200px"
                      className="figure-img img-fluid rounded mt-3"
                      alt="Not Found"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/media/bg/image-not-found.jpeg";
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-lg-6">
                <div className="form-group row">
                  <label
                    htmlFor="inputText"
                    className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                  >
                    Gift Name
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="inputText"
                      readOnly
                      value={gift?.giftId?.name || ""}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputText"
                    className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                  >
                    Gift Number
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="inputText"
                      readOnly
                      value={gift?.giftId?._id || " "}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label
                    htmlFor="inputText"
                    className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                  >
                    Quantity
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="inputText"
                      readOnly
                      value={gift?.quantity || " "}
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}

          {formik.values.reject && (
            <div className="form-group row">
              <label htmlFor="inputText" className="col-sm-2 col-form-label">
                Reject Reason:
              </label>
              <div className="col-sm-6">
                <textarea
                  className="form-control"
                  id="inputText"
                  rows={3}
                  value={formik.values.rejectReason || ""}
                  name="rejectReason"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                ></textarea>

                {formik.touched.rejectReason && formik.errors.rejectReason ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.rejectReason}
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              {entities?.status === "PENDING" ? (
                <>
                  {" "}
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => {
                      resetBeforeApprove();
                      setTimeout(() => {
                        formik.handleSubmit();
                      }, 10);
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-danger mx-2"
                    onClick={() => {
                      resetBeforeReject();
                      setTimeout(() => {
                        formik.handleSubmit();
                      }, 10);
                    }}
                  >
                    Reject
                  </button>
                </>
              ) : (
                <button
                  className="btn btn-success mr-2 mx-sm-2"
                  onClick={() => {
                    // resetBeforeApprove();
                    resetBeforeSave();
                    setTimeout(() => {
                      formik.handleSubmit();
                    }, 10);
                  }}
                >
                  Save
                </button>
              )}
            </div>
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/approval/gift-redemption">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
