import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { api } from "../../../../common/api";
import { Select } from "../../../../common/components/Select";
import * as Yup from "yup";
interface Params {
  id?: string;
}

interface Payment {
  status?: string;
}
interface Values {
  reject: boolean;
  callApi: boolean;
  rejectReason: string;
  paymentStatus: string;
}
interface Body {
  _id: string;
  status: string;
  affilatedBy: string;
  reason?: string;
  paymentStatus: string;
}

export const AgentRegistrationProfile: FC = () => {
  const history = useHistory();
  const params: Params = useParams();

  const [entities, setEntities] = useState<ApprovalEntities>();
  const [payment, setPayment] = useState("");

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/approval/agentApprovalDetails/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getData();
    } else {
      history.push("/approval/agent-registration");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkStatus = async () => {
    try {
      const { data }: any = await api({
        url: `/agent/payment/status/${params.id}`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setPayment(data.result.data.status);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reject: false,
      callApi: false,
      rejectReason: "",
      paymentStatus: "success",
    },
    validationSchema: Yup.object({
      rejectReason: Yup.string().when("reject", {
        is: true,
        then: Yup.string().required("Reject Reason is required"),
      }),
    }),
    onSubmit: (values: Values) => {
      handleReject(values, entities);
    },
  });

  const handleReject = async (values: Values, entities: any) => {
    formik.setFieldValue("reject", true);
    formik.setFieldValue("callApi", true);
    if (formik.values.reject) {
      if (formik.values.callApi) {
        handleApiCall(entities, "REJECTED");
        formik.setFieldValue("reject", false);
        formik.setFieldValue("callApi", false);
      } else {
        formik.setFieldValue("callApi", false);
      }
    }
  };
  const handleApiCall = async (values: any, status: string) => {
    try {
      let body: Body = {
        _id: values._id,
        paymentStatus: values.paymentStatus,
        status: status,
        affilatedBy: values?.user?.additionalAttribute?.agentId,
      };
      if (status === "REJECTED" && formik.values.rejectReason !== "") {
        body.reason = formik.values.rejectReason.replaceAll(
          "<p><br></p>",
          "<p></p>"
        );
      }

      const { data }: any = await api({
        url: "/admin/agent/approve",
        method: "post",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        status === "ACCEPTED"
          ? toast.success("Approved")
          : toast.success("Rejected");

        history.push("/approval/agent-registration");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const name =
    (entities && entities?.status === "PENDING"
      ? entities?.user?.additionalAttribute?.name
      : entities?.user?.name) || [];

  return (
    <div>
      <Card>
        <CardHeader title="Agent Registration Approval"></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Customer First Name
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={name || ""}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Payment
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?.transactionId ? "Online" : "Bank Transfer"}
                  />
                </div>
              </div>
            </div>  
          </div>

          <div className="row">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  HK ID Number
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={
                      entities && entities?.status === "PENDING"
                        ? entities?.user?.additionalAttribute?.hkId
                        : entities?.user?.hkId
                    }
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Apply Agent Grade
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?.currentGradeLevel?.name || ""}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4  col-form-label"
                >
                  HK ID card Photo
                </label>
                <div className="col-sm-6">
                  <img
                    src={
                      entities && entities?.status === "PENDING"
                        ? entities?.user?.additionalAttribute?.hkIdImage || ""
                        : entities?.user?.hkIdImage || ""
                    }
                    width="250px"
                    height="200px"
                    className="figure-img img-fluid rounded mt-3"
                    alt="Not Found"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = "/media/bg/image-not-found.jpeg";
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4  col-form-label"
                >
                  Amount Payble
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?.currentGradeLevel?.deposite || ""}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4  col-form-label"
                >
                  Payment Status
                </label>
                <div className="col-sm-6">
                  <Select
                    value={formik.values.paymentStatus}
                    setValue={(value: string) =>
                      formik.setFieldValue("paymentStatus", value)
                    }
                    options={[
                      { label: "SUCCESS", value: "success" },
                      { label: "PENDING", value: "pending" },
                      { label: "FAILED", value: "failed" },
                    ]}
                  />

                  {entities?.transactionId ? (
                    <>
                      <button className="mt-2" onClick={checkStatus}>
                        Check Status
                      </button>
                      <span className="ml-2 text-uppercase">{payment}</span>
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                Agent Id
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?.user?.agentToken}
                  />
                </div>
              </div>
              {entities?.docUrl ? (
                <div className="form-group row">
                  <label
                    htmlFor="inputText"
                    className="col-sm-4  col-md-2 col-lg-4  col-form-label"
                  >
                    Payment Photo
                  </label>
                  <div className="col-sm-6">
                    <img
                      src={entities?.docUrl || ""}
                      className="figure-img img-fluid rounded mt-3"
                      alt="Not Found"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/media/bg/image-not-found.jpeg";
                      }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          {formik.values.reject && (
            <div className="form-group row">
              <label htmlFor="inputText" className="col-sm-2 col-form-label">
                Reject Reason:
              </label>
              <div className="col-sm-6">
                <textarea
                  className="form-control"
                  id="inputText"
                  rows={3}
                  value={formik.values.rejectReason || ""}
                  name="rejectReason"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                ></textarea>
                {formik.touched.rejectReason && formik.errors.rejectReason ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.rejectReason}
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              {entities?.status === "PENDING" ? (
                <>
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => handleApiCall(entities, "ACCEPTED")}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-danger mx-2"
                    onClick={() => formik.handleSubmit()}
                  >
                    Reject
                  </button>
                </>
              ) : (
                <button
                  className="btn btn-success mr-2 mx-sm-2"
                  onClick={() => handleApiCall(entities, "SAVE")}
                >
                  Save
                </button>
              )}
            </div>
            <div className="mt-4 mt-sm-0">
              <Link
                className="btn btn-primary"
                to="/approval/agent-registration"
              >
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
