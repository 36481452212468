import React from "react";
import { Switch, Route } from "react-router-dom";
import { Layout } from "../layout";
import BasePage from "./BasePage";
import { AuthPage } from "../modules/Auth";
import { useSelector } from "react-redux";

export function Routes() {
  const token = useSelector((state) => state.user.appToken);

  return (
    <Switch>
      {!token ? (
        // Redirect to `/auth/login` when user is not authorized
        <AuthPage />
      ) : (
        <Layout>
          <BasePage />
        </Layout>
      )}

      {token ? (
        <Layout>
          <BasePage />
        </Layout>
      ) : (
        <Route>
          <AuthPage />
        </Route>
      )}
    </Switch>
  );
}
