import React, { FC, useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../../common/api";
import moment from "moment";

interface Params {
  id?: string;
}
interface Values {
  from: string;
  to: string;
  transmissionId: string;
  itemId: string;
  productName: string;
  transmissionDate: string;
}
export const TransmissionDetail: FC = () => {
  const history = useHistory();
  const params: Params = useParams();
  const [entities, setEntities] = useState<TransmissionDetails>();

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/transmission/transmissionDetails/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };
  useEffect(() => {
    params.id && getData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const undo = async (values: Values) => {
    try {
      const body = {
        from: values.from,
        to: values.to,
        transmissionId: values.transmissionId,
        itemId: values.itemId,
        productName: values.productName,
        transmissionDate: values.transmissionDate,
      };
      const { data }: any = await api({
        url: `/transmission/undo/${params.id}`,
        method: "get",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
        history.push("/transmission");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      from: params.id ? (entities?.from as string) : "",
      to: params.id ? (entities?.to as string) : "",
      transmissionId: params.id ? (entities?._id as string) : "",
      itemId: params.id ? (entities?.itemId as string) : "",
      productName: params.id
        ? entities?.productName
          ? (entities.productName[0] as string)
          : ""
        : "",
      transmissionDate: params.id ? (entities?.transmissionDate as string) : "",
    },
    validationSchema: Yup.object({
      transmissionId: Yup.string().required("Transmission Id is required"),
      itemId: Yup.string().required("Item Id is required"),
      productName: Yup.string().required("Product Name is required"),
      transmissionDate: Yup.string().required("Transmission Date is required"),
      from: Yup.string().required("Required"),
      to: Yup.string().required("Required"),
    }),
    onSubmit: (values: Values) => {
      params.id ? undo(values) : getData();
    },
  });

  return (
    <Card>
      <CardHeader title="Transmission"></CardHeader>
      <CardBody>
        <div className="row">
          <div className="col-lg-6 ">
            <div className="form-group row align-items-center">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                Transmission ID
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="inputText"
                  name="transmissionId"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={entities?._id || ""}
                  readOnly
                />
                {formik.touched.transmissionId &&
                formik.errors.transmissionId ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.transmissionId}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row align-items-center">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                Item ID
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="itemId"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={entities?.itemId || ""}
                    readOnly
                  />
                </div>
                {formik.touched.itemId && formik.errors.itemId ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.itemId}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row align-items-center">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 mb-lg-0"
              >
                From
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="from"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={
                      entities?.type === "undo" ? entities?.to : entities?.from
                    }
                    readOnly
                  />
                </div>
                {formik.touched.from && formik.errors.from ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.from}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-lg-6 ">
            <div className="form-group row align-items-center">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                Product Name
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="productName"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={entities?.productName || ""}
                    readOnly
                  />
                </div>
                {formik.touched.productName && formik.errors.productName ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.productName}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row align-items-center">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                Transmission Date
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="transmissionDate"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={
                      moment(entities?.transmissionDate).format("MM-DD-YYYY") ||
                      ""
                    }
                    readOnly
                  />
                </div>
                {formik.touched.transmissionDate &&
                formik.errors.transmissionDate ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.transmissionDate}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row align-items-center">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                To
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="to"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={
                      entities?.type === "undo" ? entities?.from : entities?.to
                    }
                    readOnly
                  />
                </div>
                {formik.touched.to && formik.errors.to ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.to}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="mt-4 mt-sm-0">
            {params.id && entities?.type === "transmit" && !entities?.isUndo ? (
              <button
                className="btn btn-danger mr-2 mx-sm-2"
                // @ts-ignore
                onClick={formik.handleSubmit}
              >
                Undo
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="mt-4 mt-sm-0">
            <Link className="btn btn-primary" to="/transmission">
              Back
            </Link>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};
