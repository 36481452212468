/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router-dom";

export function HeaderMenu({ layoutProps }) {
  const location = useLocation();
  let route = "";

  switch (
    location.pathname
      .split("/")
      .slice(0, 2)
      .join("/")
  ) {
    case "/article":
      route = "Article";
      break;
    case "/approval":
      route = "Approval";
      break;
    case "/member":
      route = "Member";
      break;
    case "/grade-level":
      route = "Grade Level";
      break;
    case "/blacklist":
      route = "Blacklist";
      break;
    case "/product":
      route = "Product";
      break;
    case "/eshop":
      route = "Eshop";
      break;
    case "/transmission":
      route = "Transmission";
      break;
    case "/about-us":
    case "/contact-us":
      route = "Content Management";
      break;
    case "/grade-info":
      route = "Grad Info";
      break;
    case "/privacy-policy":
    case "/terms-and-conditions":
    case "/company-account":
    case "/agent-terms-and-conditions":
    case "/official-site-url":
    case "/cash-bank":
    case "/credit-value":
    case "/special-config":
    case "/app-admin":
      route = "Settings";
      break;
    case "/qr-code":
      route = "QR Code";
      break;
    case "/sales-target":
      route = "Sales Target";
      break;
    case "/push-notification":
      route = "Push Notification";
      break;
    case "/item-maintenance":
      route = "Item Maintenance";
      break;
    default:
      route = "";
      break;
  }

  return (
    <div
      id="kt_header_menu"
      className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
      {...layoutProps.headerMenuAttributes}
    >
      {route}
    </div>
  );
}
