import React, { FC, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../common/api";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

interface Values {
  specialLoginOtp: string;
  specialLogin: string;
}

export const SpecialConfig: FC = () => {
  const [entities, setEntities] = useState<any>();
  const [specialAttibute, setSpecialAttribute] = useState<any>({});

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/specialAttribute`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        // setEntities(data.result.data);
        const newObj = {}
        for (const x of data.result.data) {
          newObj[x.attribute] = x.value
        }
        setSpecialAttribute(newObj)
        // const specialLoginOtp = entities.find(x=> x.attribute === 'SPECIAL_LOGIN_OTP')
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const updateAttribute = async (attribute, value) => {
    console.log('insideee',attribute, value);
    
    try {
      const { data }: any = await api({
        url: `/specialAttribute`,
        method: "post",
        body: {
          attribute: attribute,
          value: value,
        },
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const updateValue = (inputValue, attribute) => {
    console.log('inputValue',inputValue);
    
    setSpecialAttribute((state) => {
      return {
        ...state,
        [`${attribute}`]: inputValue
      }
    })
  }

  
  return (
    <div>
      <Card>
        <CardHeader title="Special Config"></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4  col-form-label"
                >
                  Special Login OTP
                </label>
                <div className="col-sm-4 col-lg-6 input-group">
                  <input
                    type="number"
                    className="form-control"
                    value={specialAttibute["SPECIAL_LOGIN_OTP"]}
                    onChange={(e) => updateValue(e.target.value, 'SPECIAL_LOGIN_OTP')}
                    onBlur={(e) => updateAttribute("SPECIAL_LOGIN_OTP", e.target.value)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4  col-form-label"
                >
                  Special Login
                </label>
                <div className="col-sm-4 col-lg-6 input-group relative d-flex align-items-center">
                  <select
                    className="form-control top-select rounded"
                    name="agentId"
                    value={specialAttibute["SPECIAL_LOGIN"]}
                    // onChange={(e) => updateValue(e.target.value, 'SPECIAL_LOGIN')}
                    onChange={(e) => {updateValue(e.target.value, 'SPECIAL_LOGIN');updateAttribute("SPECIAL_LOGIN", e.target.value)}}
                    style={{ paddingRight: "3rem" }}
                  >
                    <option value="" disabled>
                      -- Select --
                    </option>
                    <option value="true">True</option>
                    <option value="false">False</option>
                  </select>
                  <ExpandMoreRoundedIcon
                      style={{
                        position: "absolute",
                        right: "1rem",
                        zIndex: 10,
                      }}
                    />
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        {/* <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button className={`btn btn-success mr-2 mx-sm-2`}>Update</button>
            </div>
          </div>
        </CardFooter> */}
      </Card>
    </div>
  );
};
