import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../partials/controls";
import "react-quill/dist/quill.snow.css";
import { toast } from "react-toastify";
import { api } from "../../../../common/api";

interface Params {
  id?: string;
}

interface Values {
  reject: boolean;
  callApi: boolean;
  rejectReason: string;
}
interface Body {
  _id: string;
  status: string;
  affilatedBy: string;
  reason?: string;
}

export const AgentCashoutUpdate: FC = () => {
  const history = useHistory();
  const params: Params = useParams();

  const [entities, setEntities] = useState<Cashout>();

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/approval/cashoutApprovalDetails/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    if (params.id) {
      getData();
    } else {
      history.push("/approval/cashout");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      reject: false,
      callApi: false,
      rejectReason: "",
    },
    validationSchema: Yup.object({
      rejectReason: Yup.string().when("reject", {
        is: true,
        then: Yup.string().required("Reject Reason is required"),
      }),
    }),
    onSubmit: (values: Values) => {
      handleReject(values, entities);
    },
  });

  const handleReject = async (values: Values, entities: any) => {
    formik.setFieldValue("reject", true);
    formik.setFieldValue("callApi", true);
    if (formik.values.reject) {
      if (formik.values.callApi) {
        handleApiCall(entities, "REJECTED");
        formik.setFieldValue("reject", false);
        formik.setFieldValue("callApi", false);
      } else {
        formik.setFieldValue("callApi", false);
      }
    }
  };

  const handleApiCall = async (values: any, status: string) => {
    try {
      let body: Body = {
        _id: values._id,
        status: status,
        affilatedBy: values?.requestedBy?.affilatedBy,
      };
      if (status === "REJECTED" && formik.values.rejectReason !== "") {
        body.reason = formik.values.rejectReason.replaceAll(
          "<p><br></p>",
          "<p></p>"
        );
      }
      const { data }: any = await api({
        url: "admin/cashout/approve",
        method: "post",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        status === "ACCEPTED"
          ? toast.success("Approved")
          : toast.success("Rejected");

        history.push("/approval/cashout");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  return (
    <div>
      <Card>
        <CardHeader title="Cashout Approval"></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Customer Name
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?.requestedBy?.name || ""}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Phone Number
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?.requestedBy?.phone || ""}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4  col-form-label"
                >
                  Amounts
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={entities?.amount.value || ""}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4  col-form-label"
                >
                  Receive Method
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    value={
                      entities?.paymentMethod === "bank"
                        ? entities?.requestedBy?.cashoutDetails?.bankName?.bankName
                        : "FPS"
                    }
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4  col-form-label"
                >
                  Account Number
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    readOnly
                    // value={entities?.requestedBy?.cashoutType || ""}
                    value={entities?.requestedBy?.cashoutDetails?.accountNumber}
                  />
                </div>
              </div>
            </div>
          </div>

          {formik.values.reject && (
            <div className="form-group row">
              <label htmlFor="inputText" className="col-sm-2 col-form-label">
                Reject Reason:
              </label>
              <div className="col-sm-6">
                <textarea
                  className="form-control"
                  id="inputText"
                  rows={3}
                  value={formik.values.rejectReason || ""}
                  name="rejectReason"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                ></textarea>
                {formik.touched.rejectReason && formik.errors.rejectReason ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.rejectReason}
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              {entities?.status === "PENDING" ? (
                <>
                  <button
                    className="btn btn-success mr-2 mx-sm-2"
                    onClick={() => handleApiCall(entities, "ACCEPTED")}
                  >
                    Approve
                  </button>
                  <button
                    className="btn btn-danger mx-2"
                    onClick={() => formik.handleSubmit()}
                  >
                    Reject
                  </button>
                </>
              ) : (
                ""
              )}
            </div>
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/approval/cashout">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
