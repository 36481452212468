import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../partials/controls";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../../../helpers";
import { useFormik } from "formik";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { api } from "../../../../common/api";
import { IconButton } from "@material-ui/core";
import { Select } from "../../../../common/components/Select";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";

interface Values {
  status: boolean;
  name: string;
  phone: string;
  cashoutType: string;
  shippingRegion: string;
  shippingAddress: string;
  agentLevel: any;
  agentToken: string;
  shippingReceiver: string;
  photo: string;
  affilatedBy: string;
  hkId: string;
  registrationDate: string;
  credit: number;
}
export const AddAgent = () => {
  const history = useHistory();
  const inputFile = useRef<HTMLInputElement | null>(null);
  const [imgLoaded, setImgLoaded] = useState<boolean>(false);
  const [agentLevelOptions, setAgentLevelOptions] = useState<AgentLevel[]>();
  const [affilatedBy, setAffilatedBy] = useState<any>();

  const getAgentLevelData = async () => {
    try {
      const { data }: any = await api({
        url: `/grade/activeGrade`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setAgentLevelOptions(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getAffilatedBy = async () => {
    try {
      const { data }: any = await api({
        url: `/user/activeAgent`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setAffilatedBy(data.result.data.userData);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const addAgentDetail = async (values: Values) => {
    const body = {
      shippingAddress: {
        region: values.shippingRegion,
        address: values.shippingAddress,
      },
      name: values.name,
      isActive: values.status,
      cashoutType: values.cashoutType,
      myCredit: values.credit,
      shippingReceiver: values.shippingReceiver,
      registrationDate: values.registrationDate,
      hkId: values.hkId,
      hkIdImage: values.photo,
      phone: values.phone,
      affilatedBy: values.affilatedBy,
      agentToken: values.agentToken,
      agentLevel: values.agentLevel,
      isAgent: true,
    };
    try {
      const { data }: any = await api({
        url: `/user/add/agent`,
        method: "post",
        body: body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
        history.push("/member/agent");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const uploadImage = async (e: ChangeEvent) => {
    e.preventDefault();
    if (inputFile.current?.files) {
      const formData = new FormData();
      formData.append("file", inputFile.current.files[0]);
      try {
        const { data }: any = await api({
          url: "/file/file-upload",
          method: "post",
          body: formData,
        });
        if (data.serverResponse.isError) {
          formik.setFieldError("photo", "image is required");
          toast.error(data.serverResponse.message);
        } else {
          formik.setFieldValue("photo", data.data.fileUrl);
          toast.success("image uploaded");
        }
      } catch (err) {
        formik.setFieldError("photo", "image is required");
        toast.error("image not uploaded, please try again");
      }
    }
  };

  useEffect(() => {
    getAgentLevelData();
    getAffilatedBy();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options =
    agentLevelOptions?.map((item: any) => ({
      label: item.name,
      value: item._id,
    })) || [];

  const affilatedOptions =
    (affilatedBy?.length > 0 &&
      affilatedBy.map((option: any) => ({
        label: ` ${option.name} - ${option.phone}`,
        value: `${option._id}`,
      }))) ||
    [];

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      photo: "",
      agentLevel: (agentLevelOptions && agentLevelOptions[0]._id) || "",
      name: "",
      phone: "",
      shippingReceiver: "",
      agentToken: "",
      shippingAddress: "",
      shippingRegion: "",
      status: true,
      cashoutType: "fps",
      hkId: "",
      affilatedBy: (affilatedOptions && affilatedOptions[0]?.value) || "",
      registrationDate: "",
      credit: 0,
    },
    validationSchema: Yup.object({
      agentToken: Yup.string()
        .required("Agent id is required")
        .min(3, "length must be at least 3 characters long "),
      name: Yup.string().required("Name is required"),
      phone: Yup.string().required("Phone number is required"),
      cashoutType: Yup.string().required("Cashout type is required"),
      agentLevel: Yup.string().required("Agent Level is required"),
      credit: Yup.number()
        .required("Credit number is required")
        .min(0, "Less than 0 not allowed"),
      registrationDate: Yup.string().required("Registration date is required"),
      affilatedBy: Yup.string().required("Agent member name is required"),
    }),
    onSubmit: (values: Values) => {
      addAgentDetail(values);
    },
  });

  return (
    <div>
      {" "}
      <Card>
        <CardHeader title="Add Agent"></CardHeader>
        <CardBody>
          <div className="row mt-4">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Agent Name
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="name"
                    value={formik.values.name || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Phone Number
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="phone"
                    value={formik.values.phone || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.phone}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Agent Level
                </label>
                <div className="col-sm-6">
                  <Select
                    value={formik.values.agentLevel}
                    setValue={(value: string) =>
                      formik.setFieldValue("agentLevel", value)
                    }
                    options={options}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  HKID no.
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    name="hkId"
                    id="inputText"
                    value={formik.values.hkId || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2 col-lg-4 col-form-label"
                >
                  Affilated By
                </label>
                <div className="col-sm-6">
                  <Select
                    value={formik.values.affilatedBy}
                    setValue={(value: string) =>
                      formik.setFieldValue("affilatedBy", value)
                    }
                    options={affilatedOptions}
                  />
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Agent Id
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="agentToken"
                    name="agentToken"
                    value={formik.values.agentToken || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.agentToken && formik.errors.agentToken ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.agentToken}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4  col-form-label"
                >
                  Registration Date
                </label>
                <div className="col-sm-6">
                  <input
                    type="date"
                    className="form-control"
                    id="registrationDate"
                    name="registrationDate"
                    value={moment(
                      new Date(formik.values.registrationDate).toUTCString()
                    ).format("YYYY-MM-DD")}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.registrationDate &&
                  formik.errors.registrationDate ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.registrationDate}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2 col-lg-4 col-form-label"
                >
                  HKID Photo
                </label>
                <div className="col-sm-6">
                  {formik.values.photo && (
                    <div className="w-50 h-100">
                      <input
                        className="w-100 h-100"
                        type="file"
                        onChange={uploadImage}
                        ref={inputFile}
                        hidden
                        name="upload-image"
                        id="upload-image"
                        accept="image/jpg, image/png, image/jpeg"
                      />
                      <div className="position-relative w-200px" >
                        <img
                          src={formik?.values?.photo || ""}
                          alt=""
                          className="img-fluid"
                          onClick={() => {
                            let input = document.getElementById(
                              "upload-image"
                            )! as HTMLInputElement;
                            input.value = "";
                            inputFile.current?.click();
                          }}
                          onLoad={() => setImgLoaded(true)}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null; // prevents looping
                            currentTarget.src =
                              "/media/bg/image-not-found.jpeg";
                          }}
                        />
                        {imgLoaded && (
                          <IconButton
                            disableRipple
                            disableFocusRipple
                            className="position-absolute delete-image-btn"
                            onClick={() => formik.setFieldValue("photo", "")}
                          >
                            <CloseRoundedIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  )}
                  {!formik.values.photo && (
                    <div className="p-1 upload-image mt-4 ">
                      <input
                        className="w-50 h-100"
                        type="file"
                        onChange={uploadImage}
                        ref={inputFile}
                        hidden
                        name="upload-image"
                        id="upload-image"
                        accept="image/jpg, image/png, image/jpeg"
                      />
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        onClick={() => {
                          let input = document.getElementById(
                            "upload-image"
                          )! as HTMLInputElement;
                          input.value = "";
                          inputFile.current?.click();
                        }}
                        className="image-add-icon"
                      >
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Files/File-plus.svg"
                          )}
                        />
                      </IconButton>
                    </div>
                  )}
                  {formik.touched.photo && formik.errors.photo ? (
                    <div className="text-danger mb-0 mt-4 pt-1">
                      {formik.errors.photo}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Credit
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control"
                    name="credit"
                    id="credit"
                    value={formik.values.credit}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    min={0}
                  />
                  {formik.touched.credit && formik.errors.credit ? (
                    <div className="text-danger mb-0 mt-4 pt-1">
                      {formik.errors.credit}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Shipping Region
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="shippingRegion"
                    value={formik.values.shippingRegion || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.shippingRegion &&
                  formik.errors.shippingRegion ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.shippingRegion}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2 col-lg-4 col-form-label"
                >
                  Cashout Payment
                </label>

                <div className="col-sm-6">
                  <Select
                    value={formik.values.cashoutType}
                    setValue={(value: string) =>
                      formik.setFieldValue("cashoutType", value)
                    }
                    options={[
                      { label: "FPS", value: "fps" },
                      { label: "Bank Account", value: "bank account" },
                      { label: "Cash", value: "cash" },
                    ]}
                  />
                  {formik.touched.cashoutType && formik.errors.cashoutType ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.cashoutType}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2 col-lg-4 col-form-label"
                >
                  Contact Person
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="shippingReceiver"
                    value={formik.values.shippingReceiver || ""}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  />
                  {formik.touched.shippingReceiver &&
                  formik.errors.shippingReceiver ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.shippingReceiver}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Shipping Address
                </label>
                <div className="col-sm-6">
                  <textarea
                    className="form-control"
                    id="inputText"
                    rows={3}
                    value={formik.values.shippingAddress || ""}
                    name="shippingAddress"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                  ></textarea>
                  {formik.touched.shippingAddress &&
                  formik.errors.shippingAddress ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.shippingAddress}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-lg-6">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4 col-md-2  col-lg-4 col-form-label"
                >
                  Status
                </label>
                <div className="col-sm-6">
                  <Select
                    value={formik.values.status}
                    setValue={(value: string) =>
                      formik.setFieldValue("status", value)
                    }
                    options={[
                      { label: "Active", value: true },
                      { label: "Inactive", value: false },
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                Add
              </button>
            </div>
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/member/agent">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
