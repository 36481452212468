export const SET_USER = "SET_USER";
export const SET_BUILDING_ID = "SET_BUILDING_ID";
export const SET_BUILDING_INTERVAL = "SET_BUILDING_INTERVAL";
export const SET_BUILDING = "SET_BUILDING";
export const SET_SINGLE_BUILDING_DATA = "SET_SINGLE_BUILDING_DATA";
export const SET_BUILDING_CERTIFICATES = "SET_BUILDING_CERTIFICATES";
export const SET_BUILDING_LAYOUT = "SET_BUILDING_LAYOUT";
export const SET_BUILDINGS = "SET_BUILDINGS";
export const SET_USER_THEME = "SET_USER_THEME";
export const GET_LOCATION = "GET_LOCATION";
export const SET_LOCATION = "SET_LOCATION";
export const CLEAR = "CLEAR";
export const SET_USER_LOGO = "SET_USER_LOGO";
export const SET_LOADER = "SET_LOADER";
