import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../partials/controls";

import { IconButton } from "@material-ui/core";
import { toAbsoluteUrl } from "../../helpers";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { api } from "../../common/api";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

interface Params {
  id?: string;
}

interface Values {
  email: string;
  address: string;
  photo: string;
  phone: string;
}

export const ContactUs: FC = () => {
  const params: Params = useParams();

  const inputFile = useRef<HTMLInputElement | null>(null);
  const [entities, setEntities] = useState<LegalDetails>();
  const [imgLoaded, setImgLoaded] = useState<boolean>(false);

  const updateData = async (values: Values) => {
    try {
      const body = {
        details: {
          email: values.email,
          address: values.address
            .replace(/<p>/g, "")
            .replace(/<\/p>/g, "")
            .replaceAll("<p><br></p>", "<p></p>"),
          photo: values.photo,
          phone: values.phone,
        },

        type: "contact_us",
      };
      const { data }: any = await api({
        url: `/legalDetails`,
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/legalDetails?type=contact_us`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const uploadImage = async (e: ChangeEvent) => {
    e.preventDefault();
    if (inputFile.current?.files) {
      const formData = new FormData();
      formData.append("file", inputFile.current.files[0]);
      try {
        const { data }: any = await api({
          url: "/file/file-upload",
          method: "post",
          body: formData,
        });
        if (data.serverResponse.isError) {
          !params.id && formik.setFieldError("photo", "image is required");
          toast.error(data.serverResponse.message);
        } else {
          formik.setFieldValue("photo", data.data.fileUrl);
          toast.success("image uploaded");
        }
      } catch (err) {
        formik.setFieldError("photo", "image is required");
        toast.error("image not uploaded, please try again");
      }
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: entities?.details?.email,
      address: entities?.details?.address
        ? (entities?.details.address as string)
        : "",
      photo: entities?.details?.photo,

      phone: entities?.details?.phone,
    },

    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email")
        .required("Email is required"),
      address: Yup.string().required("Address is required"),
      photo: Yup.string().required("Image is required"),
      phone: Yup.string().required("Phone number is required"),
    }),
    onSubmit: (values: Values) => {
      updateData(values);
    },
  });

  useEffect(() => {
    if (formik.values.photo === "") {
      setImgLoaded(false);
    }
  }, [formik.values.photo]);

  return (
    <div>
      <Card>
        <CardHeader title="Contact Us"></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 col-form-label"
                >
                  Email
                </label>
                <div className="col-sm-6">
                  <input
                    type="email"
                    className="form-control"
                    id="inputText"
                    name="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                  />
                  {formik.touched.email && formik.errors.email ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.email}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 col-form-label"
                >
                  Phone Number
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control"
                    id="inputText"
                    name="phone"
                    min="0"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                  {formik.touched.phone && formik.errors.phone ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.phone}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="row"></div>

          <div className="form-group row">
            <label
              htmlFor="inputText"
              className="col-sm-4 col-md-2 col-lg-2  col-form-label"
            >
              Address
            </label>
            <div className="col-lg-8 col-sm-4 col-md-8">
              <div className="">
                <textarea
                  className="form-control"
                  id="inputText"
                  rows={3}
                  value={formik.values.address || ""}
                  name="address"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                ></textarea>

                {formik.touched.address && formik.errors.address ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.address}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="form-group row">
            <label
              htmlFor="inputText"
              className="col-sm-4 col-md-2 col-lg-2 col-form-label"
            >
              Photo
            </label>
            <div className="col-sm-6">
              {formik.values.photo && (
                <div className="w-50 h-100">
                  <input
                    className="w-100 h-100"
                    type="file"
                    onChange={uploadImage}
                    ref={inputFile}
                    hidden
                    name="upload-image"
                    id="upload-image"
                    accept="image/jpg, image/png, image/jpeg"
                  />
                  <div className="position-relative fit-content">
                    <img
                      src={formik?.values?.photo || ""}
                      alt=""
                      className="img-fluid"
                      onClick={() => {
                        let input = document.getElementById(
                          "upload-image"
                        )! as HTMLInputElement;
                        input.value = "";
                        inputFile.current?.click();
                      }}
                      onLoad={() => setImgLoaded(true)}
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = "/media/bg/image-not-found.jpeg";
                      }}
                    />
                    {imgLoaded && (
                      <IconButton
                        disableRipple
                        disableFocusRipple
                        className="position-absolute delete-image-btn"
                        onClick={() => formik.setFieldValue("photo", "")}
                      >
                        <CloseRoundedIcon />
                      </IconButton>
                    )}
                  </div>
                </div>
              )}
              {!formik.values.photo && (
                <div className="p-1 upload-image mt-4 ">
                  <input
                    className="w-50 h-100"
                    type="file"
                    onChange={uploadImage}
                    ref={inputFile}
                    hidden
                    name="upload-image"
                    id="upload-image"
                    accept="image/jpg, image/png, image/jpeg"
                  />
                  <IconButton
                    disableRipple
                    disableFocusRipple
                    onClick={() => {
                      let input = document.getElementById(
                        "upload-image"
                      )! as HTMLInputElement;
                      input.value = "";
                      inputFile.current?.click();
                    }}
                    className="image-add-icon"
                  >
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Files/File-plus.svg"
                      )}
                    />
                  </IconButton>
                </div>
              )}
              {formik.touched.photo && formik.errors.photo ? (
                <div className="text-danger mb-0 mt-4 pt-1">
                  {formik.errors.photo}
                </div>
              ) : null}
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                // @ts-ignore
                onClick={formik.handleSubmit}
              >
                Update
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
