import React, { FC, useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../../common/api";

interface Params {
  _id?: string;
}
interface Values {
  bankName: string;
}
export const CashBankUpdate: FC = () => {
  const params: Params = useParams();
  const history = useHistory();
  const [entities, setEntities] = useState<CashBankDetails>();

  const updateStatus = async () => {
    try {
      const { data }: any = await api({
        url: `/cashoutBank/updateStatus/${params._id}`,
        method: "put",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
        history.push("/cash-bank");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/cashoutBank/${params._id}`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    params._id && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addData = async (values: Values) => {
    try {
      let body = {
        bankName: values.bankName,
      };

      const { data }: any = await api({
        url: "/cashoutBank",
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Bank Name added");
        history.push("/cash-bank");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const updateData = async (values: Values) => {
    try {
      const body = {
        bankName: values.bankName,
      };
      const { data }: any = await api({
        url: `/cashoutBank/${params._id}`,
        method: "put",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Bank Name updated");
        history.push("/cash-bank");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      bankName: params._id ? (entities?.bankName as string) : "",
    },
    validationSchema: Yup.object({
      bankName: Yup.string().required("Bank Name is required"),
    }),
    onSubmit: (values: Values) => {
      params._id ? updateData(values) : addData(values);
    },
  });

  return (
    <Card>
      <CardHeader title="Cash Bank"></CardHeader>
      <CardBody>
        <div className="row">
          <div className="col-lg-10 ">
            <div className="form-group row align-items-center ml-2">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-4 col-lg-2  col-form-label  "
              >
                Bank Name
              </label>
              <div className="col-sm-8">
                <input
                  type="text"
                  className="form-control"
                  id="inputText"
                  name="bankName"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.bankName}
                />
                {formik.touched.bankName && formik.errors.bankName ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.bankName}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="mt-4 mt-sm-0">
            {params._id ? (
              <>
                {entities?.isActive ? (
                  <>
                    <button
                      className="btn btn-success mr-2 mx-sm-2"
                      // @ts-ignore
                      onClick={formik.handleSubmit}
                    >
                      Update
                    </button>
                    <button
                      className="btn btn-danger mx-2"
                      onClick={updateStatus}
                    >
                      Inactive
                    </button>
                  </>
                ) : (
                  <button
                    className="btn btn-success mx-2"
                    onClick={updateStatus}
                  >
                    Active
                  </button>
                )}
              </>
            ) : (
              <button
                className="btn btn-success mr-2 mx-sm-2"
                // @ts-ignore
                onClick={formik.handleSubmit}
              >
                Add
              </button>
            )}
          </div>
          <div className="mt-4 mt-sm-0">
            <Link className="btn btn-primary" to="/cash-bank">
              Back
            </Link>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};
