import React, { FC, useEffect, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../../common/api";
import { Select } from "../../../common/components/Select";

interface Params {
  id?: string;
}

interface Values {
  name: string;
  deposite: number;
  credit: number;
  id?: string;
  maxRequestQty: number;
  wholesaleQuantity: number;
  status: boolean;
}

export const AddEditGradeLevel: FC = () => {
  const history = useHistory();
  const params: Params = useParams();

  const [entities, setEntities] = useState<GradeLevelEntities>();

  const getGradeLevelData = async () => {
    try {
      const { data }: any = await api({
        url: `/grade/${params.id}`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      //@ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    params.id && getGradeLevelData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addGradeLevel = async (values: Values) => {
    try {
      const body = {
        name: values.name,
        deposite: values.deposite,
        credit: values.credit,
        maxRequestQty: values.maxRequestQty,
        wholesaleQuantity: values.wholesaleQuantity,
      };
      const { data }: any = await api({
        url: "/grade",
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Grade added");
        history.push("/grade-level");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const updateGradeLevel = async (values: Values) => {
    try {
      const body = {
        name: values.name,
        deposite: values.deposite,
        credit: values.credit,
        maxRequestQty: values.maxRequestQty,
        wholesaleQuantity: values.wholesaleQuantity,
        isActive: values.status,
      };
      const { data }: any = await api({
        url: `/grade/${params.id}`,
        method: "put",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Grade updated");
        history.push("/grade-level");
      }
    } catch (err) {
      //@ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: params.id ? (entities?.name as string) : "",
      deposite: params.id ? (entities?.deposite as number) : 0,
      credit: params.id ? (entities?.credit as number) : 0,
      maxRequestQty: params.id ? (entities?.maxRequestQty as number) : 0,
      wholesaleQuantity: params.id
        ? (entities?.wholesaleQuantity as number)
        : 0,
      status: params.id ? (entities?.isActive as boolean) : true,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      deposite: Yup.number()
        .required("Deposit is required")
        .min(0, "less than 0 not allowed"),
      credit: Yup.number()
        .required("Credit is required")
        .min(0, "less than 0 not allowed"),
      maxRequestQty: Yup.number()
        .required("Maximum request quanity is required")
        .min(0, "less than 0 not allowed"),
      wholesaleQuantity: Yup.number()
        .required("wholesale quanity is required")
        .min(0, "less than 0 not allowed"),
    }),
    onSubmit: (values: Values) => {
      params.id ? updateGradeLevel(values) : addGradeLevel(values);
    },
  });

  return (
    <div>
      <Card>
        <CardHeader
          title={params.id ? "Level Details" : "Add Level"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label mt-2"
                >
                  Grade Name
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 py-0 mb-lg-0 mt-2"
                >
                  Maximum Request Quantity
                </label>
                <div className="col-sm-6">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="inputText"
                      name="maxRequestQty"
                      min={0}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.maxRequestQty}
                    />
                  </div>
                  {formik.touched.maxRequestQty &&
                  formik.errors.maxRequestQty ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.maxRequestQty}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 py-0 mb-lg-0 mt-2"
                >
                  Wholesale Quantity
                </label>
                <div className="col-sm-6">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="inputText"
                      name="wholesaleQuantity"
                      min={0}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.wholesaleQuantity}
                    />
                  </div>
                  {formik.touched.wholesaleQuantity &&
                  formik.errors.wholesaleQuantity ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.wholesaleQuantity}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-3 py-0 col-form-label mt-2"
                >
                  Deposit
                </label>
                <div className="col-sm-6">
                  <div className="input-group">
                    <span className="input-group-text" id="basic-addon1">
                      $
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      id="inputText"
                      name="deposite"
                      min={0}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.deposite}
                    />
                  </div>
                  {formik.touched.deposite && formik.errors.deposite ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.deposite}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-3 py-0 col-form-label mt-2"
                >
                  Credit
                </label>
                <div className="col-sm-6">
                  <div className="input-group">
                    <input
                      type="number"
                      className="form-control"
                      id="inputText"
                      name="credit"
                      min={0}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.credit}
                    />
                  </div>
                  {formik.touched.credit && formik.errors.credit ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.credit}
                    </div>
                  ) : null}
                </div>
              </div>
              {params?.id && (
                <div className="row mt-1">
                  {/* <div className="col-12 col-sm-2 mb-sm-0 ws-nowrap">
                    Status
                  </div> */}
                  <label
                    htmlFor="inputText"
                    className="col-sm-2 col-md-2 col-lg-3 py-0 col-form-label mt-2"
                  >
                    Status
                  </label>
                  <div className="col-sm-6">
                    <Select
                      value={formik.values.status}
                      setValue={(value: string) =>
                        formik.setFieldValue("status", value)
                      }
                      options={[
                        { label: "Active", value: true },
                        { label: "Inactive", value: false },
                      ]}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                {params.id ? "Save" : "Add"}
              </button>
            </div>
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/grade-level">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
