import axios from "axios";
import { toast } from "react-toastify";
import { clearUser } from "../../redux/actions/userActions";
import { store } from "../../redux/store";

export const logout = async () => {
  try {
    const { data }: any = await axios({
      url: "/user/logout",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${store.getState().user.appToken}`,
      },
      data: {},
    });
    if (data.serverResponse.isError) {
      toast.error(data.serverResponse.message);
    } else {
      store.dispatch(clearUser());
      toast.success(data.serverResponse.message);
    }
  } catch (err) {
    toast.error("token is invalid");
    store.dispatch(clearUser());
  }
};
