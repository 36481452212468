import React, { FC, useState, useEffect } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../../../common/api";

interface Params {
  id?: string;
}
interface Values {
  name: string;
  phone: string;
  level: string;
  credit: number;
  reason: string;
}
interface Body {
  _id: string;
  status: string;
  credit: number;
  reason?: string;
}

export const MonthlyAgentCreditApprove: FC = () => {
  const params: Params = useParams();
  const history = useHistory();
  const [entities, setEntities] = useState<MonthlyAgentCreditDetail>();

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/approval/agentMonthlyCredit/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    params.id && getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async (values: any) => {
    try {
      let body: Body = {
        _id: entities?._id as string,
        status: values.status,
        credit: values.credit,
      };
      if (values?.reason && values.reason !== "") {
        body.reason = values.reason.replaceAll("<p><br></p>", "<p></p>");
      }
      const { data }: any = await api({
        url: "/admin/transmission/approve",
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        values?.status === "ACCEPTED" && toast.success("Approved");
        values?.status === "REJECTED" && toast.success("Rejected");
        history.push("/approval/monthly-agent-credit");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: params.id ? (entities?.requestedBy?.name as string) : "",
      phone: params.id ? (entities?.requestedBy?.phone as string) : "",
      level: params.id
        ? (entities?.requestedBy?.agentLevel?.name as string)
        : "",
      credit: params.id ? (entities?.credit as number) : 0,
      reason: params.id ? (entities?.reason as string) : "",
    },
    validationSchema: Yup.object({
      credit: Yup.number()
        .min(0, "credit value less than 0 is not allowed")
        .required("Credit is required"),
    }),

    onSubmit: (values: Values) => {
      handleSubmit(values);
    },
  });

  return (
    <Card>
      <CardHeader title="Monthly Agent Credit Approval"></CardHeader>
      <CardBody>
        <div className="row">
          <div className="col-lg-6 ">
            <div className="form-group row align-items-center">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                Agent Name
              </label>
              <div className="col-sm-6">
                <input
                  type="text"
                  className="form-control"
                  id="inputText"
                  name="name"
                  readOnly
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row align-items-center">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                Grade Level
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="level"
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.level}
                  />
                </div>
                {formik.touched.level && formik.errors.level ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.level}
                  </div>
                ) : null}
              </div>
            </div>
          </div>

          <div className="col-lg-6 ">
            <div className="form-group row align-items-center">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                Phone Number
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="phone"
                    min={0}
                    readOnly
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                  />
                </div>
                {formik.touched.phone && formik.errors.phone ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.phone}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row align-items-center">
              <label
                htmlFor="inputText"
                className="col-sm-2 col-md-2 col-lg-4 py-0 col-form-label"
              >
                Credit
              </label>
              <div className="col-sm-6">
                <div className="input-group">
                  <input
                    type="number"
                    className="form-control"
                    id="inputText"
                    name="credit"
                    min={0}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.credit}
                  />
                </div>
                {formik.touched.credit && formik.errors.credit ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.credit}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4 pt-4 ">
          <div className="col-12 col-sm-2 mb-4 mb-sm-0  ws-nowrap">Remarks</div>
          <div className="col-8 ">
            <div className="">
              <textarea
                className="form-control"
                id="inputText"
                rows={3}
                value={formik.values.reason || ""}
                name="reason"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              ></textarea>

              {formik.touched.reason && formik.errors.reason ? (
                <div className="text-danger mt-1 ml-1">
                  {formik.errors.reason}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </CardBody>
      <CardFooter>
        <div className="d-flex flex-wrap align-items-center justify-content-between">
          <div className="mt-4 mt-sm-0">
            {entities?.status === "PENDING" ? (
              <>
                <button
                  className="btn btn-success mr-2 mx-sm-2"
                  onClick={() => {
                    formik.setFieldValue("status", "ACCEPTED");
                    formik.handleSubmit();
                  }}
                >
                  Approve
                </button>
                <button
                  className="btn btn-danger mx-2"
                  onClick={() => {
                    formik.setFieldValue("status", "REJECTED");
                    formik.handleSubmit();
                  }}
                >
                  Reject
                </button>{" "}
              </>
            ) : (
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => {
                  formik.setFieldValue("status", "SAVE");
                  formik.handleSubmit();
                }}
              >
                Save
              </button>
            )}
          </div>
          <div className="mt-4 mt-sm-0">
            <Link
              className="btn btn-primary"
              to="/approval/monthly-agent-credit"
            >
              Back
            </Link>
          </div>
        </div>
      </CardFooter>
    </Card>
  );
};
