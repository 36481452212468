import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import { useHistory, useParams, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../../partials/controls";
import { IconButton } from "@material-ui/core";
import { toAbsoluteUrl } from "../../../helpers";
import SVG from "react-inlinesvg";
import { toast } from "react-toastify";
import { api } from "../../../common/api";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
import { Select } from "../../../common/components/Select";

interface Params {
  id?: string;
}

interface Values {
  name: string;
  type: string;
  description: string;
  imageUrl: string;
  status?: boolean;
  credit: number;
  stock: number;
  number?: number;
  id?: string;
}

export const AddEditGift: FC = () => {
  const history = useHistory();
  const params: Params = useParams();

  const inputFile = useRef<HTMLInputElement | null>(null);
  const [entities, setEntities] = useState<EshopEntities>();
  const [imgLoaded, setImgLoaded] = useState<boolean>(false);

  const getGiftData = async () => {
    try {
      const { data }: any = await api({
        url: `/gift/${params.id}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      //@ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    params.id && getGiftData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const uploadImage = async (e: ChangeEvent) => {
    e.preventDefault();
    if (inputFile.current?.files) {
      const formData = new FormData();
      formData.append("file", inputFile.current.files[0]);
      try {
        const { data }: any = await api({
          url: "/file/file-upload",
          method: "post",
          body: formData,
        });
        if (data.serverResponse.isError) {
          !params.id && formik.setFieldError("imageUrl", "image is required");
          toast.error(data.serverResponse.message);
        } else {
          formik.setFieldValue("imageUrl", data.data.fileUrl);
          toast.success("image uploaded");
        }
      } catch (err) {
        formik.setFieldError("imageUrl", "image is required");
        toast.error("image not uploaded, please try again");
      }
    }
  };

  const addGift = async (values: Values) => {
    try {
      const body = {
        name: values.name,
        description: values.description,
        imageUrl: values.imageUrl,
        credit: values.credit,
        stock: values.stock,
        type: values.type,
      };
      const { data }: any = await api({
        url: "/gift",
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Gift added");
        history.push("/eshop");
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const updateGift = async (values: Values) => {
    try {
      const body = {
        name: values.name,
        description: values.description,
        imageUrl: values.imageUrl,
        credit: values.credit,
        stock: values.stock,
        status: values.status,
        type: values.type,
      };
      const { data }: any = await api({
        url: `/gift/${params.id}`,
        method: "put",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("Gift updated");
        history.push("/eshop");
      }
    } catch (err) {
      //@ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: params.id ? (entities?.name as string) : "",
      type: params.id ? (entities?.type as string) : "gift",
      description: params.id ? (entities?.description as string) : "",
      imageUrl: params.id ? (entities?.imageUrl as string) : "",
      status: params.id ? (entities?.status as boolean) : true,
      id: params.id ? (entities?._id as string) : "",
      credit: params.id ? (entities?.credit as number) : 0,
      stock: params.id ? (entities?.stock as number) : 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      type: Yup.string().required("Type is required"),
      description: Yup.string().required("Description is required"),
      imageUrl: Yup.string().required("Image is required"),
      credit: Yup.number()
        .required("Credit is required")
        .min(0, "less than 0 not allowed"),
      stock: Yup.number()
        .required("Stock is required")
        .min(0, "less than 0 not allowed"),
    }),
    onSubmit: (values: Values) => {
      params.id ? updateGift(values) : addGift(values);
    },
  });

  useEffect(() => {
    if (formik.values.imageUrl === "") {
      setImgLoaded(false);
    }
  }, [formik.values.imageUrl]);

  return (
    <div>
      <Card>
        <CardHeader
          title={params.id ? "Gift Details" : "Add Gift"}
        ></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 col-form-label"
                >
                  Gift Name
                </label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="name"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.name}
                    </div>
                  ) : null}
                </div>
              </div>

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 col-form-label"
                >
                  Credit
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control"
                    id="inputText"
                    name="credit"
                    min="0"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.credit}
                  />
                  {formik.touched.credit && formik.errors.credit ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.credit}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="col-lg-6 ">
              {params.id && (
                <div className="form-group row">
                  <label
                    htmlFor="inputText"
                    className="col-sm-2 col-md-2 col-lg-4 col-form-label"
                  >
                    Gift Id
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="inputText"
                      name="id"
                      readOnly
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.id}
                    />
                    {formik.touched.number && formik.errors.number ? (
                      <div className="text-danger mt-1 ml-1">
                        {formik.errors.number}
                      </div>
                    ) : null}
                  </div>
                </div>
              )}

              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2 col-lg-4 col-form-label"
                >
                  Stock
                </label>
                <div className="col-sm-6">
                  <input
                    type="number"
                    className="form-control"
                    id="inputText"
                    name="stock"
                    min="0"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.stock}
                  />
                  {formik.touched.stock && formik.errors.stock ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.stock}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="col-lg-6 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-4  col-md-2 col-lg-4 col-form-label"
                >
                  Type
                </label>
                <div className="col-sm-6">
                  <Select
                    value={formik.values.type}
                    setValue={(value: string) =>
                      formik.setFieldValue("type", value)
                    }
                    options={[
                      { label: "Gift", value: "gift" },
                      { label: "Coupon", value: "coupon" },
                    ]}
                  />
                  {formik.touched.type && formik.errors.type ? (
                    <div className="text-danger mt-1 ml-1">
                      {formik.errors.type}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="form-group row">
              <label
                htmlFor="inputText"
                className="col-sm-4 col-md-2 col-lg-2  col-form-label"
              >
                Gift Desc
              </label>
              <div className="col-sm-6 ">
                <textarea
                  className="form-control"
                  id="inputText"
                  rows={3}
                  name="description"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.description}
                />
                {formik.touched.description && formik.errors.description ? (
                  <div className="text-danger mt-1 ml-1">
                    {formik.errors.description}
                  </div>
                ) : null}
              </div>
            </div>

            <div className="form-group row">
              <label
                htmlFor="inputText"
                className="col-sm-4 col-md-2 col-lg-2 col-form-label"
              >
                Gift Photo
              </label>
              <div className="col-sm-6">
                {formik.values.imageUrl && (
                  <div className="w-50 h-100">
                    <input
                      className="w-100 h-100"
                      type="file"
                      onChange={uploadImage}
                      ref={inputFile}
                      hidden
                      name="upload-image"
                      id="upload-image"
                      accept="image/jpg, image/png, image/jpeg"
                    />
                    <div className="position-relative fit-content">
                      <img
                        src={formik?.values?.imageUrl || ""}
                        alt=""
                        className="img-fluid"
                        onClick={() => {
                          let input = document.getElementById(
                            "upload-image"
                          )! as HTMLInputElement;
                          input.value = "";
                          inputFile.current?.click();
                        }}
                        onLoad={() => setImgLoaded(true)}
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null; // prevents looping
                          currentTarget.src = "/media/bg/image-not-found.jpeg";
                        }}
                      />
                      {imgLoaded && (
                        <IconButton
                          disableRipple
                          disableFocusRipple
                          className="position-absolute delete-image-btn"
                          onClick={() => formik.setFieldValue("imageUrl", "")}
                        >
                          <CloseRoundedIcon />
                        </IconButton>
                      )}
                    </div>
                  </div>
                )}
                {!formik.values.imageUrl && (
                  <div className="p-1 upload-image mt-4 ">
                    <input
                      className="w-50 h-100"
                      type="file"
                      onChange={uploadImage}
                      ref={inputFile}
                      hidden
                      name="upload-image"
                      id="upload-image"
                      accept="image/jpg, image/png, image/jpeg"
                    />
                    <IconButton
                      // className={classes.icon}
                      disableRipple
                      disableFocusRipple
                      onClick={() => {
                        let input = document.getElementById(
                          "upload-image"
                        )! as HTMLInputElement;
                        input.value = "";
                        inputFile.current?.click();
                      }}
                      className="image-add-icon"
                    >
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Files/File-plus.svg"
                        )}
                      />
                    </IconButton>
                  </div>
                )}
                {formik.touched.imageUrl && formik.errors.imageUrl ? (
                  <div className="text-danger mb-0 mt-4 pt-1">
                    {formik.errors.imageUrl}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {params?.id && (
            <div className="row mt-4 pt-4">
              <div className="col-12 col-sm-2 mb-3 mb-sm-0 ws-nowrap">
                Status
              </div>
              <div className="col-10 col-sm-4">
                <Select
                  value={formik.values.status}
                  setValue={(value: string) =>
                    formik.setFieldValue("status", value)
                  }
                  options={[
                    { label: "Active", value: true },
                    { label: "Inactive", value: false },
                  ]}
                />
              </div>
            </div>
          )}
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className="btn btn-success mr-2 mx-sm-2"
                onClick={() => formik.handleSubmit()}
              >
                {params.id ? "Save" : "Add"}
              </button>
            </div>
            <div className="mt-4 mt-sm-0">
              <Link className="btn btn-primary" to="/eshop">
                Back
              </Link>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
