import React, { FC, useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from "../../partials/controls";
import { toast } from "react-toastify";
import { api } from "../../common/api";
import { useFormik } from "formik";
import * as Yup from "yup";

interface Values {
  url: string;
}

export const OfficialSiteUrl: FC = () => {
  const [entities, setEntities] = useState<LegalDetails>();

  const updateData = async (values: Values) => {
    try {
      const body = {
        details: {
          url: values.url,
        },

        type: "official_site_url",
      };
      const { data }: any = await api({
        url: `/legalDetails`,
        method: "post",
        body,
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success(data.serverResponse.message);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getData = async () => {
    try {
      const { data }: any = await api({
        url: `/legalDetails?type=official_site_url`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setEntities(data.result.data);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      url: (entities?.details?.url as string) || "",
    },
    validationSchema: Yup.object({
      url: Yup.string().required("URL is required"),
    }),
    onSubmit: (values: Values) => {
      updateData(values);
    },
  });

  return (
    <div>
      <Card>
        <CardHeader title="Official Site Url"></CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-lg-10 ">
              <div className="form-group row">
                <label
                  htmlFor="inputText"
                  className="col-sm-2 col-md-2  col-lg-2 col-form-label"
                >
                  URL
                </label>
                <div className="col-sm-8">
                  <input
                    type="text"
                    className="form-control"
                    id="inputText"
                    name="url"
                    value={formik.values.url}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.url && formik.errors.url ? (
                    <div className="text-danger mt-1 ml-1 mb-4 pb-4">
                      {formik.errors.url}
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className="d-flex flex-wrap align-items-center justify-content-between">
            <div className="mt-4 mt-sm-0">
              <button
                className={`btn btn-success mr-2 mx-sm-2`}
                onClick={() => formik.handleSubmit()}
              >
                Update
              </button>
            </div>
          </div>
        </CardFooter>
      </Card>
    </div>
  );
};
