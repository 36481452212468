import React, { FC, useState, useEffect } from "react";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../partials/controls";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { api } from "../../common/api";
interface Select {
  value: string;
  label: string;
}

interface Values {
  type: string;
  box: number;
  code: string;
  quantity: number;
  activeAgent: string;
}

export const QrCode: FC = () => {
  const selectOptions = [
    { value: "Box", label: "Box" },
    { value: "Product", label: "Product" },
  ];

  const [entities, setEntities] = useState<ProductEntities[]>([]);
  const [activeAgent, setActiveAgent] = useState<ActiveAgent[]>([]);

  const getActiveAgent = async () => {
    try {
      const { data }: any = await api({
        url: `/user/activeAgent`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        setActiveAgent(data.result.data.userData);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const getEntities = async (page = 1, sizePerPage = 10) => {
    try {
      const { data }: any = await api({
        url: `/product?isActive=true`,
        method: "get",
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.productData.map(
          (entity: ProductEntities, ind: number) => {
            return {
              ...entity,
              isActive: entity.isActive,
              name: entity.name,
              itemCode: entity.itemCode,
            };
          }
        );

        setEntities(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getEntities();
    getActiveAgent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addProduct = async (values: Values) => {
    try {
      const body = {
        type: values.type.substring(0, 1),
        box: values.box.toString(),
        code: values.code,
        quantity: values.quantity.toString(),
        owner: values.activeAgent,
      };

      const { data }: any = await api({
        url: `/qrcode/create`,
        method: "post",
        body,
      });

      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        toast.success("OR Code Generated Successfully");
        window.location.href = data.result.url;
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      type: "Box",
      box: 1,
      code: "",
      quantity: 1,
      activeAgent: "",
    },
    validationSchema: Yup.object({
      code: Yup.string().required("Product Name is required"),
      quantity: Yup.number()
        .required("Product quantity is required")
        .when("type", {
          is: "Box",
          then: Yup.number()
            .min(2, " If choose box the product quantity must more then 1")
            .required("Product quantity is required"),
        })
        .when("type", {
          is: "Product",
          then: Yup.number()
            .min(1, "Product quantity must be more than zero")
            .required("Product quantity is required"),
        }),
      box: Yup.number().when("type", {
        is: "Box",
        then: Yup.number()
          .min(1, "Box quantity must be more than one")
          .required("Box quantity is required"),
      }),

      activeAgent: Yup.string().required("Owner is required"),
    }),
    onSubmit: (values: Values) => {
      addProduct(values);
    },
  });

  useEffect(() => {
    if (formik.values.type === "Product") {
      formik.setFieldValue("box", "");
    }
    if (formik.values.type === "Box") {
      formik.setFieldValue("box", "1");
    }
    if (formik.values.type) {
      formik.setFieldValue("quantity", "1");
    }
  }, [formik.values.type]);

  return (
    <Card>
      <CardHeader title=" Generate QR Code">
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <form>
          <div className="mt-4 mb-3">
            <label htmlFor="exampleInputEmail1">Type</label>
            <div className="d-flex relative align-items-center mt-4 mt-sm-0">
              <select
                className="form-control top-select w-100 rounded"
                name="type"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                value={formik.values.type}
                style={{ paddingRight: "3rem" }}
              >
                {selectOptions.map((option: Select, ind: number) => (
                  <option key={ind} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
              <ExpandMoreRoundedIcon
                style={{ position: "absolute", right: "3rem", zIndex: 10 }}
              />
            </div>
          </div>

          {formik.values.type === "Box" && (
            <div className="form-group mt-5">
              <label htmlFor="exampleInputEmail1">Box Quantity</label>
              <input
                type="number"
                className="form-control"
                id="inputText"
                name="box"
                min={1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.box}
              />
              {formik.touched.box && formik.errors.box ? (
                <div className="text-danger mt-1 ml-1">{formik.errors.box}</div>
              ) : null}
            </div>
          )}

          <div className="form-group mt-3">
            <label htmlFor="exampleInputPassword1">Product Name</label>

            <div className="d-flex relative align-items-center">
              <select
                className="form-control top-select rounded"
                name="code"
                onChange={formik.handleChange}
                value={formik.values.code}
                style={{ paddingRight: "3rem" }}
              >
                <option value="" disabled>
                  -- Select --
                </option>

                {entities.length > 0 &&
                  entities.map((option: ProductEntities, ind: number) => (
                    <option
                      key={ind}
                      value={option.itemCode}
                      onClick={(e) => {
                        e.preventDefault();
                        formik.setFieldValue("code", option.itemCode);
                      }}
                    >
                      {option.name}({option.itemCode})
                    </option>
                  ))}
              </select>
              <ExpandMoreRoundedIcon
                style={{ position: "absolute", right: "3rem", zIndex: 10 }}
              />
            </div>

            {formik.touched.code && formik.errors.code ? (
              <div className="text-danger mt-1 ml-1">{formik.errors.code}</div>
            ) : null}
          </div>

          <div className="form-group">
            <label htmlFor="exampleInputPassword1">Product Quantity</label>
            <input
              type="number"
              className="form-control"
              id="inputText"
              name="quantity"
              min={formik.values.type === "Box" ? 1 : 1}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.quantity}
            />
            {formik.touched.quantity && formik.errors.quantity ? (
              <div className="text-danger mt-1 ml-1">
                {formik.errors.quantity}
              </div>
            ) : null}
          </div>

          <div className="form-group mt-3">
            <label htmlFor="exampleInputPassword1">Ownership</label>

            <div className="d-flex relative align-items-center">
              <select
                className="form-control rounded"
                name="activeAgent"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.activeAgent}
                style={{ paddingRight: "3rem" }}
              >
                <option value="" disabled>
                  -- Select --
                </option>
                {activeAgent?.length > 0 &&
                  activeAgent.map((option: any) => (
                    <option key={option._id} value={option._id}>
                      {option.name} - {option.phone}
                    </option>
                  ))}
              </select>
              <ExpandMoreRoundedIcon
                style={{ position: "absolute", right: "3rem", zIndex: 10 }}
              />
            </div>

            {formik.touched.activeAgent && formik.errors.activeAgent ? (
              <div className="text-danger mt-1 ml-1">
                {formik.errors.activeAgent}
              </div>
            ) : null}
          </div>

          <button
            type="submit"
            className="btn btn-success"
            onClick={(e) => {
              e.preventDefault();

              formik.handleSubmit();
            }}
          >
            Submit
          </button>
        </form>
      </CardBody>
    </Card>
  );
};
