import React, { FC, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { sortCaret, headerSortingClasses, toAbsoluteUrl } from "../../helpers";

import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../partials/controls";

import { useHistory } from "react-router-dom";
import { SizePerPage } from "../../common/table/filters/SizePerPage";
import { Table } from "../../common/table";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { toast } from "react-toastify";
import { sortNumber } from "../../common/table/sorter";
import { FilterQuery } from "../../common/table/filters/FilterQuery";
import { api } from "../../common/api";
import { Link } from "react-router-dom";

export const CashBank: FC = () => {
  const history = useHistory();
  const [bankName, setBankName] = useState<string>("");
  const [entities, setEntities] = useState<CashBank[]>([]);
  const [totalData, setTotalData] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [sizePerPage, setSizePerPage] = useState(10);

  const getEntities = async (page = 1, sizePerPage = 10) => {
    const isBankName = bankName !== "" ? `&name=${bankName}` : "";
    try {
      const { data }: any = await api({
        url: `/cashoutbank/?page=${page}&size=${sizePerPage}${isBankName}`,
        method: "get",
      });
      if (data.serverResponse.isError) {
        toast.error(data.serverResponse.message);
      } else {
        const entities = data.result.data.bankListData.map(
          (entity: CashBank) => {
            return {
              ...entity,
              status: entity.isActive ? "Active" : "Inactive",
            };
          }
        );
        setTotalData(data.result.data.countBankList);
        setEntities(entities);
      }
    } catch (err) {
      // @ts-ignore
      err.response && toast.error(err.message);
    }
  };

  useEffect(() => {
    getEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getSelectRow = {
    mode: "radio",
    clickToSelect: true,
    hideSelectColumn: true,
    onSelect: (data: CashBank) => history.push(`/cash-bank/update/${data._id}`),
  };

  const columns = [
    {
      dataField: "bankName",
      text: "Bank Name",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortNumber(field, order, setEntities),
    },
    {
      dataField: "status",
      text: "Status",
      sort: true,
      sortCaret: sortCaret,
      headerSortingClasses,
      onSort: (field: string, order: string) =>
        sortNumber(field, order, setEntities),
    },
  ];

  const customTotal = (from: any, to: any, size: any) => (
    <span className="react-bootstrap-table-pagination-total">
      {totalData} records ({totalData === 0 ? 0 : (page - 1) * sizePerPage + 1}{" "}
      to {to})
    </span>
  );

  const handleSizePerPage = (
    { page, onSizePerPageChange }: any,
    newSizePerPage: any
  ) => {
    onSizePerPageChange(newSizePerPage, page);
  };

  const paginationOptions = {
    custom: true,
    totalSize: totalData,
    hideSizePerPage: true,
    showTotal: true,
    page,
    sizePerPage,
    paginationTotalRenderer: customTotal,
  };

  const onTableChange = async (type: any, props: any, paginationProps: any) => {
    if (type !== "sort") {
      setSizePerPage(props.sizePerPage);
      if (props.sizePerPage > paginationProps.totalSize) {
        getEntities(1, props.sizePerPage);
        setPage(1);
      } else {
        setPage(props.page);
        getEntities(props.page, props.sizePerPage);
      }
    }
  };

  return (
    <Card>
      <CardHeader title="Cash Bank">
        <CardHeaderToolbar>
          <Link className="btn btn-primary" to="/cash-bank/add">
            Add Bank
          </Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <PaginationProvider pagination={paginationFactory(paginationOptions)}>
          {({ paginationProps, paginationTableProps }) => (
            <>
              <div className="d-flex flex-column flex-sm-row flex-wrap ml-2 ">
                <SizePerPage
                  paginationProps={paginationProps}
                  handleSizePerPage={handleSizePerPage}
                  entities={entities}
                />

                <FilterQuery
                  query={bankName}
                  setQuery={setBankName}
                  title="Bank Name"
                />
                <button
                  className="btn btn-primary mt-4"
                  onClick={() => {
                    setPage(1);
                    getEntities(1, sizePerPage);
                  }}
                >
                  <span className="navigation-icon icon-white ">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
                    />
                  </span>
                </button>
              </div>
              <Table
                paginationProps={paginationProps}
                paginationOptions={paginationOptions}
                paginationTableProps={paginationTableProps}
                data={entities}
                keyField="bankName"
                getSelectRow={getSelectRow}
                columns={columns}
                remote
                onTableChange={(type: any, props: any) =>
                  onTableChange(type, props, paginationProps)
                }
              />
            </>
          )}
        </PaginationProvider>
      </CardBody>
    </Card>
  );
};
